import instance from './Instance'
import * as AppConstants from "../utils/constants/AppConstants";
import * as axios from "axios";
import {INFOBLOCK_URL_PATH} from "../utils/constants/AppConstants";

export const InfoBlockApi = {
    getInfoBlock(sid) {
        return (
            axios
                .get(AppConstants.INFOBLOCK_URL_PATH + `?sid=${sid}`)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    postInfoBlock(params) {
        return (
            axios
                .post(AppConstants.INFOBLOCK_URL_PATH, params)
                .then(response => {
                        return response.data
                    }
                )

        )
    }
}

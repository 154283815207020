import * as AppConstants from "../utils/constants/AppConstants";
import * as axios from "axios";

export const JournalApi = {
    getJournal(sid, page, objectId, step) {
        return (
            axios
                .get(AppConstants.JOURNAL_URL_PATH + `?sid=${sid}&step=${step}&page=${page}&id=${objectId}`)
                .then(response => {
                        return response.data
                    }
                )

        )
    }
}
import * as AppConstants from "../utils/constants/AppConstants";

export const ObjectsHelper = {
    setObjectId(objectId) {
        localStorage.setItem(AppConstants.OBJECT_ID, objectId)
    },
    getObjectId() {
        return localStorage.getItem(AppConstants.OBJECT_ID)
    },
    setObjects(objects) {
        localStorage.setItem(AppConstants.OBJECTS, JSON.stringify(objects))
    },
    getObjects() {
        return JSON.parse(localStorage.getItem(AppConstants.OBJECTS))
    },
    setObjectItem(object) {
        localStorage.setItem(AppConstants.OBJECT_ITEM, JSON.stringify(object))
    },
    getObjectItem() {
        return JSON.parse(localStorage.getItem(AppConstants.OBJECT_ITEM))
    },
    setObjectAdress(adress) {
        localStorage.setItem(AppConstants.OBJECT_ADRESS, adress)
    },
    getObjectAdress() {
        return localStorage.getItem(AppConstants.OBJECT_ADRESS)
    },
    setNeedGetMeter(update) {
        sessionStorage.setItem(AppConstants.NEED_GET_METERS, update)
    },
    getNeedGetMeter() {
        return sessionStorage.getItem(AppConstants.NEED_GET_METERS)
    }
}

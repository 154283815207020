
import {getSid} from "./AuthReducer";
import {SupportApi} from "../../api/SupportApi";
import {successToast} from "../../utils/Toasts";
import {AuthHelper} from "../../helpers/AuthHelper";
import {reset} from "redux-form";

const SET_SUPPORT_TEXT = 'SET_SUPPORT_TEXT'

let initialState = {
    supportSubject: "",
    supportText: ""
}

const SupportReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUPPORT_TEXT: {
            return {
                ...state,
                supportText: action.supportText,
                supportSubject: action.supportSubject
            }
        }

        default:
            return state
    }
}

//****ACTION CREATOR****//

export const setSupportText = (supportText, supportSubject) => ({
    type: SET_SUPPORT_TEXT, supportText, supportSubject
})

//****THUNK****//

export const sendSupportToPost = (params) => {
    let param = params
    return (
        (dispatch) => {
            SupportApi.sendSupportToPost(param)
                .then(data => {
                    if (data.status === 'ok') {
                        let promiseSetFields = dispatch(setSupportText("", ""))
                        Promise.all([promiseSetFields]).then(() => {
                            successToast("Ваше обращение успешно отправлено")
                        }).then(() => {
                            dispatch(reset('supportForm'))
                        })
                    } else {
                        let error = data.errors[0].name
                        if (error === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(sendSupportToPost(param))
                            })
                        }
                    }
                })
        }
    )
}

export default SupportReducer

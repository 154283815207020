import * as AppConstants from "../utils/constants/AppConstants";

export const AuthHelper = {
    setUserDataInLocalStorage(login, password) {
        localStorage.setItem(AppConstants.USER_LOGIN, login)
        localStorage.setItem(AppConstants.USER_PASSWORD, password)
    },
    setUserDataInSessionStorage(login, password) {
        sessionStorage.setItem(AppConstants.USER_LOGIN, login)
        sessionStorage.setItem(AppConstants.USER_PASSWORD, password)
    },
    getCurrentUser() {
        if (sessionStorage.getItem(AppConstants.USER_LOGIN)) return sessionStorage.getItem(AppConstants.USER_LOGIN)
        return localStorage.getItem(AppConstants.USER_LOGIN)
    },
    getCurrentUserPassword() {
        if (sessionStorage.getItem(AppConstants.USER_PASSWORD)) return sessionStorage.getItem(AppConstants.USER_PASSWORD)
        return localStorage.getItem(AppConstants.USER_PASSWORD)
    },
    setSid(sid) {
        localStorage.setItem(AppConstants.SID, sid)
    },
    getSid() {
        return localStorage.getItem(AppConstants.SID)
    },
    setRole(role) {
        sessionStorage.setItem(AppConstants.ROLE, role)
    },
    getRole() {
        return sessionStorage.getItem(AppConstants.ROLE)
    },

}

import {getSid, setAuth} from "./AuthReducer";
import {AuthHelper} from "../../helpers/AuthHelper";
import {getObjectAccess, getObjectItem, getObjects, setObjectIdThunk} from "./ObjectReducer";
import {getUserProfile} from "./ProfileReducer";
import {
    getMeters,
} from "./MetersReducer";
import {ObjectsHelper} from "../../helpers/ObjectsHelper";
import moment from "moment";
import * as AppConstants from "../../utils/constants/AppConstants";
import {getJournal} from "./JournalReducer";
import {getAllSendData} from "./SendDataReducer";
import {getUserInfoBlock} from "./InfoBlocksReducer";

const UPDATE_PLEASE = "UPDATE_PLEASE"
const INITIALIZED_APP = "INITIALIZED_APP"

let initialState = {
    update: 0,
    initialized: false
}

const AppReducer = (state = initialState, action) => {
    switch (action.type) {

        case UPDATE_PLEASE: {
            debugger
            return {
                ...state,
                update: action.update
            }
        }

        case INITIALIZED_APP: {
            return {
                ...state,
                initialized: true
            }
        }

        default:
            return state
    }
}

//****ACTION CREATOR****//

export const setUpdatePage = (update) => ({
    type: UPDATE_PLEASE, update
})

export const setInitializedApp = () => ({
    type: INITIALIZED_APP
})

//****THUNK****//
export const updateApplication = (update) => (dispatch) => {
    return (
        (dispatch) => {
            dispatch(setUpdatePage(update))
        }
    )
}

export const initializeApplication = () => (dispatch) => {

    const delayIncrement = 500
    let delay = 0

    const p1 = new Promise(resolve => setTimeout(resolve, delay)).then(() => dispatch(getUserInfoBlock(AuthHelper.getSid())))
    delay += delayIncrement

    const p2 = new Promise(resolve => setTimeout(resolve, delay)).then(() => dispatch(getObjects(AuthHelper.getSid())))
    delay += delayIncrement

    const p3 = new Promise(resolve => setTimeout(resolve, delay)).then(() => dispatch(getUserProfile(AuthHelper.getSid())))

    Promise.all([p1, p2, p3]).then(res => {

        if (ObjectsHelper.getObjects().length > 0) {
            dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId() ? ObjectsHelper.getObjectId() : 0, moment({
                hours: 23,
                minutes: 59,
                seconds: 59
            }).format("YYYY-MM-DDThh:mm:ss")))
        }

    }).then(() => {
        dispatch(setAuth(true))
    })
}

// export const initializeApplication = () => (dispatch) => {
//
//     let getUserInfoBlockFun = dispatch(getUserInfoBlock(AuthHelper.getSid()))
//     let getObjectsFun = dispatch(getObjects(AuthHelper.getSid()))
//     let getUserProfileFun = dispatch(getUserProfile(AuthHelper.getSid()))
//
//     Promise.all(
//         [
//             getUserInfoBlockFun,
//             getObjectsFun,
//             getUserProfileFun
//         ]
//     ).then(res => {
//         debugger
//         if (ObjectsHelper.getObjects().length > 0) {
//             dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId() ? ObjectsHelper.getObjectId() : 0, moment({
//                 hours: 23,
//                 minutes: 59,
//                 seconds: 59
//             }).format("YYYY-MM-DDThh:mm:ss")))
//         }
//     }).then(() => {
//         dispatch(setAuth(true))
//     })
// }

// export const initializeApplication = () => (dispatch) => {
//     Promise.all([
//         dispatch(getUserInfoBlock(AuthHelper.getSid()))
//     ]).then(() => {
//         dispatch(getObjects(AuthHelper.getSid()))
//     }).then(() => {
//         dispatch(getUserProfile(AuthHelper.getSid()))
//     }).then(() => {
//         if (ObjectsHelper.getObjects().length > 0) {
//             dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId() ? ObjectsHelper.getObjectId() : 0, moment({
//                 hours: 23,
//                 minutes: 59,
//                 seconds: 59
//             }).format("YYYY-MM-DDThh:mm:ss")))
//         }
//     }).then(() => {
//         dispatch(setAuth(true))
//     })
// }

export const initializeApplicationWithToken = () => (dispatch) => {
    Promise.all([
        dispatch(getObjects(AuthHelper.getSid()))
    ]).then(() => {
        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId() ? ObjectsHelper.getObjectId() : 0, moment({hours: 23,minutes: 59,seconds: 59}).format("YYYY-MM-DDThh:mm:ss")))
    }).then(() => {
        dispatch(getUserProfile(AuthHelper.getSid()))
    }).then(() => {
        dispatch(setAuth(true))
    })
}

export const initializeApplicationWithTokenAndRole = () => (dispatch) => {
    Promise.all([
        ObjectsHelper.getObjectId() ? dispatch(getObjectItem(AuthHelper.getSid(), ObjectsHelper.getObjectId() ? ObjectsHelper.getObjectId() : 0)) :  dispatch(getObjects(AuthHelper.getSid()))
    ]).then(() => {
        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId() ? ObjectsHelper.getObjectId() : 0, moment({hours: 23,minutes: 59,seconds: 59}).format("YYYY-MM-DDThh:mm:ss")))
    }).then(() => {
        dispatch(setAuth(true))
    })
}

export const setNeedUpdate = (update) => {
    return (
        (dispatch) => {
            dispatch(setUpdatePage(update))
        }
    )
}

export default AppReducer

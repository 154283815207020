import * as AppConstants from "../utils/constants/AppConstants";

export const MetersHeler = {
    setEircArray(array) {
        localStorage.setItem("EIRC_ARRAY", JSON.stringify(array))
    },
    getEircArray() {
        return JSON.parse(localStorage.getItem("EIRC_ARRAY"))
    },
    setGraphicItem(graphic) {
        localStorage.setItem("GRAPHIC_ITEM", JSON.stringify(graphic))
    },
    getGraphicItem() {
        return JSON.parse(localStorage.getItem("GRAPHIC_ITEM"))
    },
    setControllerItem(controller) {
        localStorage.setItem(AppConstants.CONTROLLER, JSON.stringify(controller))
    },
    getControllerItem() {
        return JSON.parse(localStorage.getItem(AppConstants.CONTROLLER))
    },
    setMeterItem(controller) {
        localStorage.setItem(AppConstants.METER_ITEM, JSON.stringify(controller))
    },
    getMeterItem() {
        return JSON.parse(localStorage.getItem(AppConstants.METER_ITEM))
    },
    setMeterId(objectId) {
        localStorage.setItem(AppConstants.METER_ID, objectId)
    },
    getMeterId() {
        return localStorage.getItem(AppConstants.METER_ID)
    },
    setMeterType(type) {
        localStorage.setItem("METER_TYPE", type)
    },
    getMeterType() {
        return localStorage.getItem("METER_TYPE")
    },
    setReaduotDt(controllerReadOutDate) {
        localStorage.setItem("READOUT", controllerReadOutDate)
    },
    getReaduotDt() {
        return localStorage.getItem("READOUT")
    },
    setMeterAbsolute(item) {
        localStorage.setItem(AppConstants.ABSOLUTE_GRAPHIC, item)
    },
    getMeterAbsolute() {
        return localStorage.getItem(AppConstants.ABSOLUTE_GRAPHIC)
    },
    setControllerReadOut(item) {
        localStorage.setItem(AppConstants.CONTROLLER_READOUT, item)
    },
    getControllerReadOut() {
        return localStorage.getItem(AppConstants.CONTROLLER_READOUT)
    },
    setEventsId(item) {
        localStorage.setItem(AppConstants.EVENTS_ID, JSON.stringify(item))
    },
    getEventsId() {
        return JSON.parse(localStorage.getItem(AppConstants.EVENTS_ID))
    },
    setGraphicsId(item) {
        localStorage.setItem(AppConstants.GRAPHICS_ID, JSON.stringify(item))
    },
    getGraphicsId() {
        return JSON.parse(localStorage.getItem(AppConstants.GRAPHICS_ID))
    },
    setMetersId(item) {
        localStorage.setItem(AppConstants.METERS_ID, JSON.stringify(item))
    },
    getMetersId() {
        return JSON.parse(localStorage.getItem(AppConstants.METERS_ID))
    },
    setReadingsId(item) {
        localStorage.setItem(AppConstants.READINGS_ID, JSON.stringify(item))
    },
    getReadingsId() {
        return JSON.parse(localStorage.getItem(AppConstants.READINGS_ID))
    },
    setSingleMetersId(item) {
        sessionStorage.setItem(AppConstants.SMALL_METER, JSON.stringify(item))
    },
    getSingleMetersId() {
        return JSON.parse(sessionStorage.getItem(AppConstants.SMALL_METER))
    },
    setSearchDate(item) {
        sessionStorage.setItem(AppConstants.SEARCH_DATE, JSON.stringify(item))
    },
    getSearchDate() {
        return JSON.parse(sessionStorage.getItem(AppConstants.SEARCH_DATE))
    },
    setEventsArray(events) {
        localStorage.setItem(AppConstants.EVENTS_ARRAY, JSON.stringify(events))
    },
    getEventsArray() {
        return JSON.parse(localStorage.getItem(AppConstants.EVENTS_ARRAY))
    },
    setGraphicsArray(events) {
        localStorage.setItem(AppConstants.GRAPHICS_ARRAY, JSON.stringify(events))
    },
    getGraphicsArray() {
        return JSON.parse(localStorage.getItem(AppConstants.GRAPHICS_ARRAY))
    },

    setAllSensors(sensors) {
        localStorage.setItem(AppConstants.ALL_SENSORS_ARRAY, JSON.stringify(sensors))
    },
    getAllSensors() {
        return JSON.parse(localStorage.getItem(AppConstants.ALL_SENSORS_ARRAY))
    },

    setMetersArray(events) {
        localStorage.setItem(AppConstants.METERS_ARRAY, JSON.stringify(events))
    },
    getMetersArray() {
        return JSON.parse(localStorage.getItem(AppConstants.METERS_ARRAY))
    },
}

import axios from "axios";

const instance = axios.create({
    baseURL: "https://testapi.saures.ru",
    timeout: 10000,
    withCredentials: false,
    headers:{ 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8;application/json' }
})

export default instance

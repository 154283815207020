import * as axios from "axios";
import * as AppConstants from "../utils/constants/AppConstants";

export const SupportApi = {
    sendSupportToPost(params) {
        return (
            axios
                .post(AppConstants.SUPPORT_URL_PATH, params)
                .then(response => {
                        return response.data
                    }
                )

        )
    }
}
import { combineReducers } from "redux";
import settings from "./settings/Reducer";
import AuthReducer from "./reducers/AuthReducer";
import { reducer as formReducer } from "redux-form";
import ProfileReducer from "./reducers/ProfileReducer";
import ObjectReducer from "./reducers/ObjectReducer";
import AppReducer from "./reducers/AppReducer";
import MeterReducer from "./reducers/MetersReducer";
import JournalReducer from "./reducers/JournalReducer";
import SendDataReducer from "./reducers/SendDataReducer";
import SupportReducer from "./reducers/SupportReducer";
import PaymentReducer from "./reducers/PaymentReducer";
import InfoBlocksReducer from "./reducers/InfoBlocksReducer";
import ChannelsReducer from "./reducers/ChannelsReducer";

const Reducers = combineReducers({
  settings,
  app: AppReducer,
  auth: AuthReducer,
  form: formReducer,
  profilePage: ProfileReducer,
  objectsPage: ObjectReducer,
  metersPage: MeterReducer,
  channelsPage: ChannelsReducer,
  journalPage: JournalReducer,
  sendDataPage: SendDataReducer,
  supportPage: SupportReducer,
  paymentPage: PaymentReducer,
  infoblockPage: InfoBlocksReducer
})

export default Reducers;

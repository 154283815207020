import {SendDataApi} from "../../api/SendDataApi";
import {getSid} from "./AuthReducer";
import {AuthHelper} from "../../helpers/AuthHelper";
import {ObjectsHelper} from "../../helpers/ObjectsHelper";
import {errorToast, successToast} from "../../utils/Toasts";

const SET_SCHEDULERS = 'SET_SCHEDULERS'
const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
const SET_SUCCESS_NOTIFICATION = 'SET_SUCCESS_NOTIFICATION'
const SET_SUCCESS_SCHEDULER = 'SET_SUCCESS_SCHEDULER'
const CLEAR_EVENTS = 'CLEAR_EVENTS'
const SET_TEMPLATES = 'SET_TEMPLATES'
const SET_EIRC_NUMS = 'SET_EIRC_NUMS'

let initialState = {
    schedulers: null,
    sendTemplates: null,
    notifications: null,
    successNotification: [-1, false],
    successScheduler: {id: -1, success: false},
    eircNums: []
}

const SendDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EIRC_NUMS: {
            return {
                ...state,
                eircNums: [...action.eircNums]
            }
        }

        case SET_TEMPLATES: {
            return {
                ...state,
                sendTemplates: [...action.sendTemplates]
            }
        }

        case SET_SCHEDULERS: {
            return {
                ...state,
                schedulers: [...action.schedulers]
            }
        }

        case SET_NOTIFICATIONS: {
            return {
                ...state,
                notifications: [...action.notifications]
            }
        }

        case SET_SUCCESS_NOTIFICATION: {
            return {
                ...state,
                successNotification: action.successNotification
            }
        }

        case SET_SUCCESS_SCHEDULER: {
            return {
                ...state,
                successScheduler: action.successScheduler
            }
        }

        case CLEAR_EVENTS: {
            //debugger
            return {
                schedulers: null,
                notifications: null
            }
        }

        default:
            return state
    }
}

//****ACTION CREATOR****//

export const setEircNums = (eircNums) => ({
    type: SET_EIRC_NUMS, eircNums
})

export const setSchedulers = (schedulers) => ({
    type: SET_SCHEDULERS, schedulers
})

export const setTemplates = (sendTemplates) => ({
    type: SET_TEMPLATES, sendTemplates
})

export const setNotifications = (notifications) => ({
    type: SET_NOTIFICATIONS, notifications
})

export const setSuccessNotification = (successNotification) => ({
    type: SET_SUCCESS_NOTIFICATION, successNotification
})

export const setSuccessScheduler = (successScheduler) => ({
    type: SET_SUCCESS_SCHEDULER, successScheduler
})

export const clearEventsA = () => ({
    type: CLEAR_EVENTS
})

//****THUNK****//

export const setEircNumbersArray = (eircNumsArray) => (dispatch) => {
    dispatch(setEircNums(eircNumsArray))
}

export const setSuccessNotificationTh = (id, successSave) => (dispatch) => {
    let array = [id, successSave]
    dispatch(setSuccessNotification(id, array))
}

export const setSuccessSchedulerTh = (id, successScheduler) => (dispatch) => {
    let array = {
        id: id,
        success: successScheduler
    }

    dispatch(setSuccessScheduler(array))
}

export const clearEventsSendT = () => {
    return (
        (dispatch) => {
            dispatch(clearEventsA())
        }
    )
}

export const getAllSendData = (sid, objectId) => (dispatch) => {
    Promise.all([
        dispatch(getSchedulers(sid, objectId))
    ]).then(() => {
        dispatch(getTemplates(sid))
    }).then(() => {
        dispatch(getNotifications(sid, objectId))
    })
}

export const getSchedulers = (sid, objectId) => {
    return (
        (dispatch) => {
            SendDataApi.getSchedulers(sid, objectId)
                .then(data => {
                    if (data.status === 'ok') {
                        dispatch(setSchedulers(data.data))
                    } else {
                        let error = data.errors[0].name
                        if (error === "WrongSIDException") {
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                dispatch(getSchedulers(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                            })
                        }
                    }
                })
        }
    )
}

export const getTemplates = (sid) => {
    return (
        (dispatch) => {
            SendDataApi.getTemplates(sid)
                .then(data => {
                    if (data.status === 'ok') {
                        let templatesArray = []
                        Object.entries(data.data).map(([key, value]) => {
                            templatesArray.push({value: key, label: value})
                        })
                        dispatch(setTemplates(templatesArray))
                    } else {
                        let error = data.errors[0].name
                        if (error === "WrongSIDException") {
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                dispatch(getTemplates(AuthHelper.getSid()))
                            })
                        }
                    }
                })
        }
    )
}

export const getNotifications = (sid, objectId) => {
    return (
        (dispatch) => {
            SendDataApi.getNotifications(sid, objectId)
                .then(data => {
                    if (data.status === 'ok') {
                        dispatch(setNotifications(data.data))
                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                dispatch(getNotifications(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                            })
                        }
                    }
                })
        }
    )
}

///////////////////// - УВЕДОМЛЕНИЯ - /////////////////////

export const postCreateNotification = (params) => {
    let param = params
    return (
        (dispatch) => {
            SendDataApi.postEditNotification(param)
                .then(data => {
                    if (data.status === 'ok') {
                        successToast("Успешно создано")
                        sessionStorage.setItem("successCreateNotification", "true")
                        dispatch(getNotifications(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                    } else {
                        sessionStorage.setItem("successCreateNotification", "false")
                        if (data.errors[0].receiver !== undefined) {
                            errorToast(data.errors[0].receiver[0])
                        }
                        if (data.errors[0].dispatch !== undefined) {
                            errorToast(data.errors[0].dispatch[0])
                        }

                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(postCreateNotification(param))
                            })
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "AbstractException") {
                            errorToast(data.errors[0].msg)
                        }
                    }
                })
        }
    )
}

export const postEditNotification = (params) => {
    let param = params
    return (
        (dispatch) => {
            SendDataApi.postEditNotification(param)
                .then(data => {
                    if (data.status === 'ok') {
                        successToast("Успешно отредактировано")
                        sessionStorage.removeItem("sendId")
                        dispatch(getNotifications(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                    } else {
                        sessionStorage.setItem("sendId", param.get("id"))
                        dispatch(setSuccessNotificationTh(true))
                        if (data.errors[0].receiver !== undefined) {
                            errorToast(data.errors[0].receiver[0])
                        }
                        if (data.errors[0].dispatch !== undefined) {
                            errorToast(data.errors[0].dispatch[0])
                        }

                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(postEditNotification(param))
                            })
                        }

                        if (data.errors[0].name === "AbstractException") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }
                    }
                })
        }
    )
}

export const postDeleteNotification = (params) => {
    let param = params
    return (
        (dispatch) => {
            SendDataApi.postEditNotification(param)
                .then(data => {
                    // debugger
                    if (data.status === 'ok') {
                        successToast("Успешно удалено")
                        dispatch(getNotifications(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(postDeleteNotification(param))
                            })
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "AbstractException") {
                            errorToast(data.errors[0].msg)
                        }
                    }
                })
        }
    )
}

export const postManualNotification = (params) => {
    let param = params
    return (
        (dispatch) => {
            SendDataApi.postEditNotification(param)
                .then(data => {
                    if (data.status === 'ok') {
                        successToast("Успешно отправлено")
                        dispatch(getNotifications(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(postManualNotification(param))
                            })
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "AbstractException") {
                            errorToast(data.errors[0].msg)
                        }
                    }
                })
        }
    )
}

///////////////////// - РАСПИСАНИЯ - /////////////////////



export const postCreateScheduler = (params) => {
    let param = params
    return (
        (dispatch) => {
            SendDataApi.postEditScheduler(param)
                .then(data => {
                    // debugger
                    if (data.status === 'ok') {
                        successToast("Успешно создано")
                        sessionStorage.setItem("successCreateSchedule", "true")
                        let promiseSetSuccess = dispatch(setSuccessNotificationTh(true))
                        Promise.all([promiseSetSuccess]).then(() => {
                            dispatch(getSchedulers(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                        })
                    } else {
                        sessionStorage.setItem("successCreateSchedule", "false")
                        dispatch(setSuccessNotificationTh(false))
                        if (data.errors[0].resource !== undefined) {
                            errorToast(data.errors[0].resource[0])
                        }
                        if (data.errors[0].receiver !== undefined) {
                            errorToast(data.errors[0].receiver[0])
                        }
                        if (data.errors[0].day !== undefined) {
                            errorToast(data.errors[0].day[0])
                        }
                        if (data.errors[0].time !== undefined) {
                            errorToast(data.errors[0].time[0])
                        }
                        if (data.errors[0].personal_account !== undefined) {
                            errorToast(data.errors[0].personal_account[0])
                        }

                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(postCreateScheduler(param))
                            })
                        }

                        if (data.errors[0].name === "AbstractException") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "SystemError") {
                            errorToast("Неизвестная ошибка")
                        }
                    }
                })
        }
    )
}

export const postEditScheduler = (params) => {
    let param = params
    return (
        (dispatch) => {
            SendDataApi.postEditScheduler(param)
                .then(data => {
                    if (data.status === 'ok') {
                        sessionStorage.removeItem("sendId")
                        dispatch(setSuccessSchedulerTh(false))
                        successToast("Успешно отредактировано")
                        dispatch(getSchedulers(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                    } else {
                        sessionStorage.setItem("sendId", param.get("id"))
                        dispatch(setSuccessSchedulerTh(true))
                        if (data.errors[0].resource !== undefined) {
                            errorToast(data.errors[0].resource[0])
                        }
                        if (data.errors[0].receiver !== undefined) {
                            errorToast(data.errors[0].receiver[0])
                        }
                        if (data.errors[0].day !== undefined) {
                            errorToast(data.errors[0].day[0])
                        }
                        if (data.errors[0].time !== undefined) {
                            errorToast(data.errors[0].time[0])
                        }
                        if (data.errors[0].personal_account !== undefined) {
                            errorToast(data.errors[0].personal_account[0])
                        }

                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(postEditScheduler(param))
                            })
                        }

                        if (data.errors[0].name === "SystemError") {
                            errorToast("Неизвестная ошибка")
                        }

                        if (data.errors[0].name === "AbstractException") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }
                    }
                })
        }
    )
}

export const postDeleteScheduler = (params) => {
    let param = params
    return (
        (dispatch) => {
            SendDataApi.postEditScheduler(param)
                .then(data => {
                    // debugger
                    if (data.status === 'ok') {
                        successToast("Успешно удалено")
                        dispatch(getSchedulers(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(postDeleteScheduler(param))
                            })
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "AbstractException") {
                            errorToast(data.errors[0].msg)
                        }
                    }
                })
        }
    )
}

export const postManualScheduler = (params) => {
    let param = params
    return (
        (dispatch) => {
            SendDataApi.postEditScheduler(param)
                .then(data => {
                    // debugger
                    if (data.status === 'ok') {
                        successToast("Успешно отправлено")
                        dispatch(getSchedulers(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(postManualScheduler(param))
                            })
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "AbstractException") {
                            errorToast(data.errors[0].msg)
                        }
                    }
                })
        }
    )
}


export default SendDataReducer

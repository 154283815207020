import {ProfileApi} from "../../api/ProfileApi"
import * as AppConstants from "../../utils/constants/AppConstants";
import {LoginApi} from "../../api/LoginApi";
import {initializeApplication, initializedSuccess, setNeedUpdate, setUpdatePage} from "./AppReducer";
import {getSid} from "./AuthReducer";
import {AuthHelper} from "../../helpers/AuthHelper";
import {errorToast, successToast, warnToast} from "../../utils/Toasts";
import {InfoBlockApi} from "../../api/InfoBlockApi";

const SET_INFOBLOCK = 'SET_INFOBLOCK'
const SET_RESPONSE_INFOBLOCK = 'SET_RESPONSE_INFOBLOCK'
const SET_RESET_URL = 'SET_RESET_URL'

let initialState = {
    infoBlocks: [],
    errors: null,
    status: null,
    responseInfoBlock: null
}

const InfoBlocksReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_INFOBLOCK: {
            return {
                ...state,
                infoBlocks: [...action.infoBlocks]
            }
        }

        case SET_RESPONSE_INFOBLOCK: {
            return {
                ...state,
                responseInfoBlock: action.responseInfoBlock
            }
        }

        case SET_RESET_URL: {
            return {
                ...state,
                responseInfoBlock: null
            }
        }

        default: {
            return state
        }
    }
}

export const setInfoBlockInfo = (infoBlocks) => ({
    type: SET_INFOBLOCK, infoBlocks
})

export const setResetUrl = () => ({
    type: SET_RESET_URL
})

export const setResponseInfoBlockInfo = (responseInfoBlock) => ({
    type: SET_RESPONSE_INFOBLOCK, responseInfoBlock
})

export const getUserInfoBlock = (sid) => {
    return (
        (dispatch) => {
            InfoBlockApi.getInfoBlock(sid)
                .then(data => {
                    if (data.status === 'ok') {

                        let allBlocks = data.data
                        let structureInfoBlock = []

                        let modalInfoBlock = []
                        let dashBoardInfoBlock = []
                        let sidebarInfoBlock = []
                        let profileInfoBlock = []

                        if (allBlocks) {
                            allBlocks.map((block, i) => {
                                if (block.embedding_type === 1) modalInfoBlock.push(block)
                                if (block.embedding_type === 2) dashBoardInfoBlock.push(block)
                                if (block.embedding_type === 3) sidebarInfoBlock.push(block)
                                if (block.embedding_type === 4) profileInfoBlock.push(block)
                            })
                        }

                        if (modalInfoBlock.length !== 0) {
                            structureInfoBlock.push(modalInfoBlock.sort(() => Math.random() - Math.random()).find(() => true))
                        }
                        if (dashBoardInfoBlock.length !== 0) {
                            structureInfoBlock.push(dashBoardInfoBlock.sort(() => Math.random() - Math.random()).find(() => true))
                        }
                        if (sidebarInfoBlock.length !== 0) {
                            structureInfoBlock.push(sidebarInfoBlock.sort(() => Math.random() - Math.random()).find(() => true))
                        }
                        if (profileInfoBlock.length !==0) {
                            structureInfoBlock.push(profileInfoBlock.sort(() => Math.random() - Math.random()).find(() => true))
                        }

                        console.log("structureInfoBlock", structureInfoBlock)

                        dispatch(setInfoBlockInfo(structureInfoBlock))
                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            Promise.all([
                                dispatch(getSid())
                            ]).then(() => {
                                dispatch(getUserInfoBlock(AuthHelper.getSid()))
                            })
                        }
                    }
                })
        }
    )
}

export const postUserInfoBlock = (params, arr) => {

    let param = params

    return (
        (dispatch) => {

            if (arr) {
                dispatch(setInfoBlockInfo(arr))
            }

            InfoBlockApi.postInfoBlock(param)
                .then(data => {
                    if (data.status === 'ok') {

                        // if (data.data.url !== undefined && data.data.url !== "") {
                        //     dispatch(setResponseInfoBlockInfo(data.data.url))
                        // }

                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            Promise.all([
                                dispatch(getSid())
                            ]).then(() => {
                                dispatch(postUserInfoBlock(param))
                            })
                        }
                    }
                })
        }
    )
}

export const resetUrlInfoBlock = () => {
    return (
        (dispatch) => {
            dispatch(setResetUrl())
        }
    )
}

export default InfoBlocksReducer

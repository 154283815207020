import {objectsApi} from "../../api/ObjectsApi";
import {getSid} from "./AuthReducer";
import {AuthHelper} from "../../helpers/AuthHelper";
import {ObjectsHelper} from "../../helpers/ObjectsHelper";
import moment from "moment";
import {errorToast, successToast} from "../../utils/Toasts";

import {getObjects} from "./ObjectReducer";
import {getMeters, setErrorDevices, updateController} from "./MetersReducer";
import {setMinutes} from "../../utils/Settings";


const SET_GET_R8_DEVICES = 'SET_GET_R8_DEVICES'
const SET_GET_SETTINGS_CHANNEL = 'SET_GET_SETTINGS_CHANNEL'
const SET_GET_R8_DEVICES_ADD = 'SET_GET_R8_DEVICES_ADD'
const SET_ADD_R8_DEVICES = 'SET_ADD_R8_DEVICES'
const SET_ERROR_R8_DEVICES = 'SET_ERROR_R8_DEVICES'
const SET_USE_INPUTS = 'SET_USE_INPUTS'
const SET_CLEAR_DEVICES = 'SET_CLEAR_DEVICES'
const SET_DEVICES_LOADED = 'SET_DEVICES_LOADED'
const SET_SETTINGS_LOADED = 'SET_SETTINGS_LOADED'
const SET_EDIT_LOADED = 'SET_EDIT_LOADED'
const SET_CLEAR_SETTINGS = 'SET_CLEAR_SETTINGS'
const SET_INIT_VALUES_SETTINGS_CHANNEL = 'SET_INIT_VALUES_SETTINGS_CHANNEL'
const SET_SETTINGS_SUCCESS = 'SET_SETTINGS_SUCCESS'

let initialState = {

    devicesR8: [],
    addDevicesR8Const: false,
    devicesIsLoaded: false,
    useInputs: [],
    settingsChannel: null,
    settingsIsLoaded: false,
    editIsLoaded: false,
    settingsSuccess: false,
    initValuesChannelEdit: null,
    errorR8Device: null,

}

const ChannelsReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_GET_SETTINGS_CHANNEL: {
            return {
                ...state,
                settingsChannel: {...action.settingsChannel}
            }
        }

        case SET_INIT_VALUES_SETTINGS_CHANNEL: {
            return {
                ...state,
                initValuesChannelEdit: {...action.initValuesChannelEdit}
            }
        }

        case SET_GET_R8_DEVICES: {
            return {
                ...state,
                devicesR8: [...action.devicesR8]
            }
        }

        case SET_SETTINGS_LOADED: {
            return {
                settingsIsLoaded: action.settingsIsLoaded
            }
        }

        case SET_EDIT_LOADED: {
            return {
                editIsLoaded: action.editIsLoaded
            }
        }

        case SET_SETTINGS_SUCCESS: {
            return {
                ...state,
                settingsSuccess: action.settingsSuccess
            }
        }

        case SET_GET_R8_DEVICES_ADD: {
            return {
                ...state,
                devicesR8: [...state.devicesR8, ...action.devicesR8]
            }
        }

        case SET_CLEAR_DEVICES: {
            return {
                ...state,
                devicesR8: []
            }
        }

        case SET_CLEAR_SETTINGS: {
            return {
                settingsChannel: null
            }
        }

        case SET_ADD_R8_DEVICES: {
            return {
                ...state,
                addDevicesR8Const: action.addDevicesR8Const
            }
        }

        case SET_DEVICES_LOADED: {
            return {
                ...state,
                devicesIsLoaded: action.devicesIsLoaded
            }
        }

        case SET_USE_INPUTS: {
            return {
                ...state,
                useInputs: [...action.useInputs]
            }
        }

        case SET_ERROR_R8_DEVICES: {
            return {
                ...state,
                errorR8Device: action.errorR8Device
            }
        }

        default:
            return state
    }
}

//****ACTION CREATOR****//\\

export const setErrorR8Devices = (errorR8Device) => ({
    type: SET_ERROR_R8_DEVICES,
    errorR8Device
})

export const setAddR8Devices = (addDevicesR8Const) => ({
    type: SET_ADD_R8_DEVICES,
    addDevicesR8Const
})

export const setGetR8Devices = (devicesR8) => ({
    type: SET_GET_R8_DEVICES,
    devicesR8
})

export const setGetSettingsChannel = (settingsChannel) => ({
    type: SET_GET_SETTINGS_CHANNEL,
    settingsChannel
})

export const setGetR8DevicesAdd = (devicesR8) => ({
    type: SET_GET_R8_DEVICES_ADD,
    devicesR8
})


export const setUseInputs = (useInputs) => ({
    type: SET_USE_INPUTS,
    useInputs
})

export const setClearDevices = () => ({
    type: SET_CLEAR_DEVICES
})

export const setClearSettings = () => ({
    type: SET_CLEAR_DEVICES
})

export const setSettingsSuccess = (settingsSuccess) => ({
    type: SET_SETTINGS_SUCCESS,
    settingsSuccess
})

export const setDevicesIsLoaded = (devicesIsLoaded) => ({
    type: SET_DEVICES_LOADED,
    devicesIsLoaded
})

export const setSettingsIsLoaded = (settingsIsLoaded) => ({
    type: SET_SETTINGS_LOADED,
    settingsIsLoaded
})

export const setEditIsLoaded = (editIsLoaded) => ({
    type: SET_EDIT_LOADED,
    editIsLoaded
})

//****THUNK****//

export const setNewDevicesToArray = (arrOfDevices) => (dispatch) => {
    dispatch(setGetR8DevicesAdd(arrOfDevices))
}

export const setDevicesToArray = (arrOfDevices) => (dispatch) => {
    dispatch(setGetR8Devices(arrOfDevices))
}

export const clearDevices = () => (dispatch) => {
    dispatch(setClearDevices())
}

export const clearSettings = () => (dispatch) => {
    dispatch(setClearSettings())
    dispatch(setSettingsIsLoaded(false))
    dispatch(setEditIsLoaded(false))
}


export const settingsSuccessF = (success) => (dispatch) => {
    dispatch(setSettingsSuccess(success))
}


export const getSettingsChannel = (sid, id, route) => (dispatch) => {
    let mId = id
    return objectsApi.getSettingsChannel(AuthHelper.getSid(), mId)
        .then(data => {
                if (data.status === 'ok') {

                    if (route === "channel") {
                        dispatch(setSettingsIsLoaded(true))
                    }

                    if (route === "treshold") {
                        dispatch(setEditIsLoaded(true))
                    }

                    if (Object.keys(data.data).length !== 0) {

                        let obj = data.data

                        if (data.data.hasOwnProperty("pv") && !data.data.hasOwnProperty("sv")) {
                            obj = {
                                sv: data.data.pv
                            }
                        }

                        dispatch(setGetSettingsChannel({...obj, mId}))
                    } else {
                        dispatch(setGetSettingsChannel({mId: mId}))
                    }

                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        let promiseGetSid = dispatch(getSid())
                        Promise.all([promiseGetSid]).then(() => {
                            dispatch(getSettingsChannel(AuthHelper.getSid(), mId))
                        })
                    } else {
                        Object.entries(data.errors[0]).map(([key, value], i) => {
                            if (value !== "") errorToast(value)
                        })
                    }
                }
            }
        )
}

export const setSettingsChannel = (params) => (dispatch) => {
    let param = params
    return objectsApi.setSettingsChannel(param)
        .then(data => {
                if (data.status === 'ok') {
                    successToast("Настройки сохранены")
                    dispatch(setSettingsIsLoaded(false))
                    dispatch(setEditIsLoaded(false))
                } else {

                    if (data.errors[0].name === "WrongSIDException") {
                        params.delete('sid')
                        let promiseGetSid = dispatch(getSid())
                        Promise.all([promiseGetSid]).then(() => {
                            param.append('sid', AuthHelper.getSid());
                            dispatch(setSettingsChannel(param))
                        })
                    } else {
                        Object.entries(data.errors[0]).map(([key, value], i) => {
                            if (value !== "") errorToast(value)
                        })
                    }

                    // if (data.errors[0].name === "PermissionDenied") {
                    //     errorToast(data.errors[0].msg)
                    // }
                    //
                    // if (data.errors[0].name === "AbstractException") {
                    //     errorToast(data.errors[0].msg)
                    // }
                    //
                    // if (data.errors[0].name === "SystemError") {
                    //     errorToast("Неизвестная ошибка")
                    // }

                }
            }
        )
}

export const getR8Devices = (sid, sn) => (dispatch) => {
    let mSn = sn
    return objectsApi.getR8Device(AuthHelper.getSid(), mSn)
        .then(data => {

                dispatch(setAddR8Devices(false))
                dispatch(setErrorR8Devices(null))

                if (data.status === 'ok') {

                    let d = data.data
                    let arrOfDevices = []
                    let useInputs = []
                    let arr = []

                    Object.entries(d).map(([key, value], i) => {

                        let keySplit1 = Number(key.split('_')[0])
                        let meterType = d[keySplit1 + "_meter_type"]
                        let signalType = d[keySplit1 + "_signal_type"]

                        if (meterType !== 0 && signalType !== 0) {
                            if (!arrOfDevices[keySplit1]) {
                                arrOfDevices[keySplit1] = {}
                            }
                            arrOfDevices[keySplit1][key] = d[key]
                            arrOfDevices[keySplit1]["input"] = keySplit1
                            arrOfDevices[keySplit1]["new"] = 0
                            arrOfDevices[keySplit1]["meterType"] = d[keySplit1 + "_meter_type"]
                            if (!useInputs.includes(keySplit1)) useInputs.push(keySplit1)
                        }

                    })

                    arrOfDevices.map((item, i) => {
                        arr.push(item)
                    })

                    dispatch(setDevicesIsLoaded(true))
                    dispatch(setGetR8Devices(arr))
                    dispatch(setUseInputs(useInputs))

                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        let promiseGetSid = dispatch(getSid())
                        Promise.all([promiseGetSid]).then(() => {
                            dispatch(getR8Devices(AuthHelper.getSid(), mSn))
                        })
                    } else if (data.errors[0].sn) {
                        // errorToast(data.errors[0].sn[0])
                        dispatch(setErrorR8Devices(data.errors[0].sn[0]))
                    } else {
                        Object.entries(data.errors[0]).map(([key, value], i) => {
                            if (value !== "" && key === "msg") errorToast(value)
                        })
                    }
                }
            }
        )
}

export const addDevicesR8 = (params) => (dispatch) => {
    let param = params
    let getDevSn = params.get("sn")
    return objectsApi.addDeviceToControllerR8(param)
        .then(data => {
                dispatch(setAddR8Devices(false))

                if (data.status === 'ok') {
                    dispatch(setAddR8Devices(true))
                    Promise.all([
                        // successToast("Устройства успешно добавлены в кабинет")
                        dispatch(getObjects(AuthHelper.getSid()))
                    ]).then(() => {
                        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment(setMinutes()).format("YYYY-MM-DDTHH:mm:ss")))
                    })

                } else {
                    Object.entries(data.errors).map(([key, value]) => {
                        let a
                        let name
                        for (let i = 0; i < 99; i++) {
                            a = value.hasOwnProperty(i + "_name")
                            if (a === true) {
                                name = i + "_name"
                                console.log(data.errors[0][name][0])
                                errorToast(data.errors[0][name][0])
                            }
                        }
                    })

                    dispatch(setAddR8Devices(false))

                    if (data.errors[0].name === "WrongSIDException") {
                        params.delete('sid')
                        let promiseGetSid = dispatch(getSid())
                        Promise.all([promiseGetSid]).then(() => {
                            param.append('sid', AuthHelper.getSid());
                            dispatch(addDevicesR8(param))
                        })
                    } else {
                        Object.entries(data.errors[0]).map(([key, value], i) => {
                            if (value !== "" && key === "msg") errorToast(value)
                        })
                    }




                    // if (data.errors[0].sn && data.errors[0].sn !== null) { // уберу ошибки, так как выше написал вывод любой из ошибок
                    //     errorToast("Неверный серийный номер")
                    // }
                    //
                    // if (data.errors[0].id && data.errors[0].id !== null) {
                    //     errorToast("Неверный идентификатор объекта")
                    // }


                }
            }
        )
}



export default ChannelsReducer

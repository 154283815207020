import humidity_sensor from "../../../assets/images/sesors_icon/ic_humidity_sensor.png";
import virtual_meter from "../../../assets/images/sesors_icon/ic_virtual_meter.png";
import virtual_measurer from "../../../assets/images/sesors_icon/ic_virtual_measurer.png";
import virtual_sensor from "../../../assets/images/sesors_icon/ic_virtual_sensor.png";
import virtual_sensor_active from "../../../assets/images/sesors_icon/ic_virtual_sensor_active.png";
import cold_water from "../../../assets/images/sesors_icon/ic_cold_water.png";
import warm_water from "../../../assets/images/sesors_icon/ic_warm_water.png";
import gaz from "../../../assets/images/sesors_icon/ic_gaz.png";
import leak_active from "../../../assets/images/sesors_icon/ic_leak_active.png";
import leak from "../../../assets/images/sesors_icon/ic_leak.png";
import temper_active from "../../../assets/images/sesors_icon/ic_temper_active.png";
import temper from "../../../assets/images/sesors_icon/ic_temper.png";
import state_active from "../../../assets/images/sesors_icon/ic_state_active.png";
import state from "../../../assets/images/sesors_icon/ic_state.png";
import heat from "../../../assets/images/sesors_icon/ic_heat.png";
import electro from "../../../assets/images/sesors_icon/ic_electro.png";
import sensor_active from "../../../assets/images/sesors_icon/ic_sensor_active.png";
import sensor from "../../../assets/images/sesors_icon/ic_sensor.png";
import kran_active from "../../../assets/images/sesors_icon/ic_kran_active.png";
import kran from "../../../assets/images/sesors_icon/ic_kran.png";
import kran_gray from "../../../assets/images/sesors_icon/ic_kran_gray.png";
import barometer_active from "../../../assets/images/sesors_icon/ic_barometer_active.png";
import barometer from "../../../assets/images/sesors_icon/ic_barometer.png";
import unknown from "../../../assets/images/sesors_icon/ic_unknown.png";
import attention from "../../../assets/images/sesors_icon/ic_attention.png";
import signal_bars_100 from "../../../assets/images/icon/signal-bars-100.png";
import signal_bars_80 from "../../../assets/images/icon/signal-bars-80.png";
import signal_bars_60 from "../../../assets/images/icon/signal-bars-60.png";
import signal_bars_40 from "../../../assets/images/icon/signal-bars-40.png";
import signal_bars_20 from "../../../assets/images/icon/signal-bars-20.png";
import hourglass from "../../../assets/images/icon/hourglass.gif";
import moment from "moment";
import React from "react";
import semver from "lodash";
import { isMeter, isMeasuringSensor, isContactSensor, isVirtualDevice } from "../../../utils/deviceTypeChecker";

export let checkForSortInDashboard = (type) => {
    if (isVirtualDevice(type)) return 1
    else if (isMeter(type)) return 2
    else if (isMeasuringSensor(type) || isContactSensor(type)) return 3
    else return 4
}
export let eventsMeterIdArrayCheck = (type, array) => {
    debugger
    if (array.some(s => s === type)) return true
    else return false
}

export let showStLk = (hw, fw) => {

    // Поля должны отображаться только если: (аппаратура < 7.0 И прошивка >= 4.4.2) или (аппаратура >= 7.0 И прошивка >=1.5.0). стало

    let hw_7_0 = '7.0'
    let fw_4_4_2 = '4.4.2'
    let fw_1_5_0 = '1.5.0'

    if (
        (semver.gt(hw_7_0, hw) === true && fw === fw_4_4_2) ||
        (semver.gt(hw_7_0, hw) === true && semver.lt(fw_4_4_2, fw))
    ) {
        return true
    }

    if (
        (hw === hw_7_0 && fw === fw_1_5_0) ||
        (semver.lt(hw_7_0, hw) === true && fw === fw_1_5_0) ||
        (semver.lt(hw_7_0, hw) === true && semver.lt(fw_1_5_0, fw))
    ) {
        return true
    }

}

export let showStLkInController = (hw, fw) => {

    // Поля должны отображаться только если: (аппаратура < 7.0 И прошивка >= 4.4.2) или (аппаратура >= 7.0 И прошивка >=1.5.0). стало

    let hw_7_0 = '7.0'
    let fw_1_5_0 = '1.5.0'

    if (
        (hw === hw_7_0 && fw === fw_1_5_0) ||
        (semver.lt(hw_7_0, hw) === true && fw === fw_1_5_0) ||
        (semver.lt(hw_7_0, hw) === true && semver.lt(fw_1_5_0, fw))
    ) {
        return true
    }

}

export let batteryLevel = (percent) => {
    if (percent <= 20) return "fas fa-battery-empty"
    else if (percent <= 29) return "fas fa-battery-quarter"
    else if (percent <= 59) return "fas fa-battery-half"
    else if (percent <= 79) return "fas fa-battery-three-quarters"
    else return "fas fa-battery-full"
}

export let signalBars = (rssi) => {
    if      (rssi >= -59) return signal_bars_100
    else if (rssi >= -69) return signal_bars_80
    else if (rssi >= -79) return signal_bars_60
    else if (rssi >= -89) return signal_bars_40
    else return signal_bars_20

}

export let categoryName = (category) => {
    switch (category) {
        case 1:
            return "Виртуальные устройства"
        case 2:
            return "Показания счетчиков"
        case 3:
            return "Состояние датчиков"
        case 4:
            return "Состояние кранов и реле"
        default:
            return ""
    }
}

export let setValsSum = (vals) => {
    if (vals !== null) {
        return vals.reduce((total, currentValue) => total = total + currentValue, 0)
    } else {
        return 0
    }
}

export let setMeasures = (meterType) => {
    switch (meterType) {
        case 1:
        case 2:
        case 3:
            return " " + "м³"
        case 5:
            return " " + "°C"
        case 7:
        case 8:
            return " " + "кВт·ч"
        case 11:
        case 12:
        case 13:
            return " " + "Гкал"
        case 14:
            return " " + "бар"
        case 15:
            return ['°C', '%'];
        default:
            return  "";
    }
}

export let dateCustom = {
    hours: 23,
    minutes: 59,
    seconds: 59
}

export let valid = function (current) {
    return current.isBefore(moment().subtract(1, "seconds"));
}

export let setValue = (meterType, valsSum, activeText, passiveText) => {

    if (!meterType) return "Неизвестно"

    switch (meterType.number) {
        case 1:
        case 2:
        case 3:
        case 7:
        case 8:
        case 11:
        case 12:
        case 13:
        case 1003:{
            let valString = valsSum.toFixed(3)
            if (valString.substr(valString.length - 1) === "0") valString = valsSum.toFixed(2).replace(".00", ".0")
            if (valString.substr(valString.length - 1) === "0" && valString.substr(valString.length - 2) === "0") valString = valsSum.toFixed(1)
            if (valString === "0.00") valString = "0.0"
            return valString
        }
        case 5:
        case 14:    
        case 1000: {
            return valsSum.toFixed(1)
        }
        case 15: {
            let valString = valsSum;
            return valString.toFixed(1)
        }
        case 4:
            return valsSum !== 0 ? "Активен" : "Неактивен"
        case 6:
        case 9:
        case 1001: {
            return valsSum !== 0 ? activeText : passiveText
        }
        case 10:
            if (valsSum === 0) return "Открыт"
            else if (valsSum === 1) return "Закрыт"
            else if (valsSum === -1) return "Среднее или обрыв"
            else if (valsSum === -2) return "Модуль не подключен"
            break
        default:
            return "Неизвестно"
    }
}

export let hardwareVersionCheck = (version) => {
    switch (version) {
        case "1.3": case "1.4": case "1.5": return "C1"
        case "3.1": case "3.2": case "3.4": case "3.5": return "R1"
        case "4.0": return "R2"
        case "4.1": return "R4"
        case "4.5": return "R2"
        case "6.3": return "R5"
        case "7.2": case "7.3": return "R6"
        case "8.2": case "8.3": return "R7"
        case "9.1": return "R8"
        default: return ""
    }
}

export let meterTypeName = (meterType) => {
    switch (meterType) {
        case 0: return "Неизвестное устройство"
        case 1: return "Холодная вода"
        case 2: return "Горячая вода"
        case 3: return "Газ"
        case 4: return "Протечка"
        case 5: return "Температура"
        case 6: return "Кран/реле"
        case 7: case 13: return "Тепло"
        case 8: return "Электричество"
        case 9: return "Датчик"
        case 10: return "Состояние крана"
        case 14: return "Давление"
        case 15: return "Влажность и температура"
        case 1000: return "Виртуальный измеритель"
        case 1001: return "Виртуальный датчик"
        case 1003: return "Виртуальный счётчик"
        default: return ""
    }
}

export let meterTypeNameR8 = (meterType) => {
    switch (meterType) {
        case 0: return "Неизвестное устройство"
        case 1: return "Холодная вода"
        case 2: return "Горячая вода"
        case 3: return "Газ"
        case 4: return "Тепло, Вт*ч"
        case 5: return "Тепло, ккал"
        case 6: return "Электричество"
        case 7: return "Температура"
        case 8: return "Датчик"
        case 9: return "Давление"
        default: return ""
    }
}

export let isDanger = (code) => {
    return code !== 0;
}

export let setCardColor = (isDanger, setActiveCard) => {
    if (isDanger && !setActiveCard) return "danger"
    else if (!isDanger && setActiveCard)  return "warning"
    else return ""
}

export let setTextColor = (isDanger, setActiveCard) => {
    // if (isDanger) return "text-white"
    // else if (setActiveCard)  return "text-white"
    // else return "text-dark"

    return "text-dark"
}

export let setActive = (meterType, valsSum) => {

    if (!meterType) return false

    switch (meterType.number) {
        case 4:
        case 6:
        case 9:
        case 1001:
            return valsSum !== 0
        case 10:
            return valsSum === 1
        default: return false
    }
}

export let hourglassPick = (time) => {
    return(
        <img src={hourglass} width={time}/>
    )
}

export let iconHelper = (type, setActiveCard, rawValue) => {
    switch (type) {
        case 1: {
            return cold_water
        }
        case 2: {
            return warm_water
        }
        case 3: {
            return gaz
        }
        case 4: {
            return setActiveCard ? leak_active : leak
        }
        case 5: {
            return setActiveCard ? temper_active : temper
        }
        case 6: {
            return setActiveCard ? state_active : state
        }
        case 7: {
            return heat
        }
        case 8: {
            return electro
        }
        case 9: {
            return setActiveCard ? sensor_active : sensor
        }
        case 10: {
            if (setActiveCard) return kran_active
            if (rawValue === 0) return kran
            if (rawValue === -1) return attention
            if (rawValue === -2) return unknown
            break
        }
        case 11: {
            return heat
        }
        case 12: {
            return heat
        }
        case 13: {
            return heat
        }
        case 14: {
            return setActiveCard ? barometer_active : barometer
        }
        case 15: {
            return humidity_sensor
        }
        case 1000: {
            return virtual_measurer
        }
        case 1001: {
            return setActiveCard ? virtual_sensor_active : virtual_sensor
        }
        case 1003: {
            return virtual_meter
        }
        default: {
            return unknown
        }
    }
}


import {JournalApi} from "../../api/JournalApi";
import {initializeApplication} from "./AppReducer";
import * as AppConstants from "../../utils/constants/AppConstants";
import {getSid} from "./AuthReducer";
import {AuthHelper} from "../../helpers/AuthHelper";
import {ObjectsHelper} from "../../helpers/ObjectsHelper";
import {successToast} from "../../utils/Toasts";
import {SupportApi} from "../../api/SupportApi";
import {reset} from "redux-form";
import {setSupportText} from "./SupportReducer";

const SET_JOURNAL = 'SET_JOURNAL'
const SET_JOURNAL_FOR_EIRC = 'SET_JOURNAL_FOR_EIRC'
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
const SET_TOTAL_COUNT = 'SET_TOTAL_COUNT'
const SET_FETCH = 'SET_FETCH'
const SET_NEED_UPDATE_SID = 'SET_NEED_UPDATE_SID'
const CLEAR_EVENTS = 'CLEAR_EVENTS'
const CLEAR_EVENTS_FOR_EIRC = 'CLEAR_EVENTS_FOR_EIRC'

let initialState = {
    events: [],
    eventsForEirc: [],
    count: 1,
    page: AppConstants.JOURNAL_PAGE,
    step: AppConstants.JOURNAL_STEP,
    currentPage: 1,
    isFetching: false,
    isNeedSidUpdate: false
}

const JournalReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_JOURNAL: {
            return {
                ...state,
                events: action.events
            }
        }

        case SET_JOURNAL_FOR_EIRC: {
            return {
                ...state,
                eventsForEirc: action.eventsForEirc
            }
        }

        case SET_NEED_UPDATE_SID: {
            return {
                ...state,
                isNeedSidUpdate: action.isNeedSidUpdate
            }
        }

        case SET_CURRENT_PAGE: {
            return {
                ...state,
                currentPage: action.currentPage
            }
        }

        case SET_TOTAL_COUNT: {
            return {
                ...state,
                count: action.count
            }
        }

        case SET_FETCH: {
            return {
                ...state,
                isFetching: action.isFetching
            }
        }

        case CLEAR_EVENTS: {
            return {
                ...state,
                events: []
            }
        }

        default:
            return state
    }
}

//****ACTION CREATOR****//

export const setJournal = (events) => ({
    type: SET_JOURNAL, events
})

export const setJournalForEirc = (eventsForEirc) => ({
    type: SET_JOURNAL_FOR_EIRC, eventsForEirc
})

export const setUpdateSid = (isNeedNewSid) => ({
    type: SET_NEED_UPDATE_SID, isNeedNewSid
})

export const setTotalCount = (count) => ({
    type: SET_TOTAL_COUNT, count
})

export const setCurrentPage = (currentPage) => ({
    type: SET_CURRENT_PAGE, currentPage
})

export const setFetching = (isFetching) => ({
    type: SET_FETCH,
    isFetching
})

export const clearEventsA = () => ({
    type: CLEAR_EVENTS
})


export const clearEventsForEircA = () => ({
    type: CLEAR_EVENTS_FOR_EIRC
})

//****THUNK****//

export const getJournal = (sid, page, objectId, step) => {

    let mPage = page
    let mStep = step

    return (
        (dispatch) => {
            JournalApi.getJournal(sid, mPage, objectId, mStep)
                .then(data => {
                    if (data.status === 'ok') {
                        dispatch(setJournal(data.data.events))
                        dispatch(setTotalCount(data.data.count))

                        dispatch(setCurrentPage(mPage))
                        dispatch(setFetching(true))

                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                dispatch(getJournal(AuthHelper.getSid(), mPage, ObjectsHelper.getObjectId(), mStep))
                            })
                        }
                    }
                })
        }
    )
}

export const getJournalForEirc = (sid, page, objectId, step) => {

    let mPage = page
    let mStep = step

    return (
        (dispatch) => {
            JournalApi.getJournal(sid, mPage, objectId, mStep)
                .then(data => {
                    if (data.status === 'ok') {
                        dispatch(setJournalForEirc(data.data.events))
                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                dispatch(getJournal(AuthHelper.getSid(), mPage, ObjectsHelper.getObjectId(), mStep))
                            })
                        }
                    }
                })
        }
    )
}

export const setCurrentPageT = (page) => {
    return (
        (dispatch) => {
            dispatch(setCurrentPage(page))
        }
    )
}

export const setFetchingT = (isFetching) => {
    return (
        (dispatch) => {
            dispatch(setFetching(isFetching))
        }
    )
}

export const clearEventsT = () => {
    return (
        (dispatch) => {
            dispatch(clearEventsA())
        }
    )
}

export const clearEventsForEircT = () => {
    return (
        (dispatch) => {
            dispatch(clearEventsForEircA())
        }
    )
}

export const clearEirc = () => {
    return (
        (dispatch) => {
            dispatch(reset('AddEircToDevices'))
        }
    )
}

export default JournalReducer

import AppReducer, {setUpdatePage} from "./AppReducer";
import {objectsApi} from "../../api/ObjectsApi";
import {errorToast, successToast} from "../../utils/Toasts";
import {AuthHelper} from "../../helpers/AuthHelper";
import {ObjectsHelper} from "../../helpers/ObjectsHelper";
import {getSid} from "./AuthReducer";
import {getObjectTariffs} from "./ObjectReducer";
import {PaymentApi} from "../../api/PaymentApi";

const SET_PAYMENT = "SET_PAYMENT"
const SET_BANK_ACCOUNT_MESSAGE = "SET_BANK_ACCOUNT_MESSAGE"

let initialState = {
    payment: null,
    bank_account: null
}

const PaymentReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_PAYMENT: {
            return {
                ...state,
                payment: action.payment
            }
        }

        case SET_BANK_ACCOUNT_MESSAGE: {
            debugger
            return {
                ...state,
                bank_account: action.bank_account
            }
        }

        default:
            return state
    }
}

//****ACTION CREATOR****//

export const setPaymentPage = (payment) => ({
    type: SET_PAYMENT, payment
})

export const setBankAccountMessage = (bank_account) => ({
    type: SET_BANK_ACCOUNT_MESSAGE, bank_account
})

//thunk

export const setBankAccountMessageThunk = (message) => {
    return (
        (dispatch) => {
            dispatch(setBankAccountMessage(message))
        }
    )
}

export const postPayment = (params) => {
    let param = params
    let paymentMethod = params.get("payment_method")

    return (
        (dispatch) => {
            PaymentApi.payment_create(param)
                .then(data => {
                    if (data.status === 'ok') {
                        if (paymentMethod === "bank_account") {
                            dispatch(setBankAccountMessage("Ожидайте получение счета в течении 1-3 рабочих дней на вашу электронную почту"))
                        } else {
                            dispatch(setPaymentPage(data.data))
                        }
                        dispatch(getObjectTariffs(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(postPayment(param))
                            })
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "AbstractException") {
                            errorToast(data.errors[0].msg)
                            return
                        }

                        if (data.errors[0].name === "SystemError") {
                            errorToast("Неизвестная ошибка")
                            return
                        }
                    }
                })
        }
    )
}

export default PaymentReducer


import {LoginApi} from "../../api/LoginApi";
import {AuthHelper} from "../../helpers/AuthHelper";
import * as AppConstants from "../../utils/constants/AppConstants";
import {
    initializeApplication,
    initializeApplicationWithToken,
    initializeApplicationWithTokenAndRole,
    setNeedUpdate
} from "./AppReducer";
import {getObjectAccess, getObjects} from "./ObjectReducer";
import {getUserProfile} from "./ProfileReducer";
import {getJournal} from "./JournalReducer";
import {ObjectsHelper} from "../../helpers/ObjectsHelper";
import {getMeterData, getMeters} from "./MetersReducer";
import moment from "moment";
import {getAllSendData} from "./SendDataReducer";
import {errorToast, successToast} from "../../utils/Toasts";
import useSyncSetState from "use-sync-set-state";
import {ROLE_LOGIN, ROLE_PASSWORD, SUPERVISOR_LOGIN, SUPERVISOR_PASSWORD} from "../../utils/constants/AppConstants";
import {getUserInfoBlock} from "./InfoBlocksReducer";

const SET_LOGIN_DATA = 'SET_LOGIN_DATA'
const SET_SID = 'SET_SID'
const SET_API = 'SET_API'
const SET_FETCH = 'SET_FETCH'
const SET_AUTH = 'SET_AUTH'
const SET_AUTH_ERROR = 'SET_AUTH_ERROR'
const SET_USER_CREATE = 'SET_USER_CREATE'
const SET_TELEGRAM = 'SET_TELEGRAM'

let login
let password

let initialState = {
    sid: null,
    api: null,
    login: null,
    password: null,
    isFetching: false,
    telegramLogin: false,
    isAuth: false,
    errorText: null,
    userCreate: false
}

const AuthReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_LOGIN_DATA: {
            return {
                ...state,
                login: action.login,
                password: action.password
            }
        }

        case SET_USER_CREATE: {
            return {
                userCreate: action.userCreate
            }
        }

        case SET_SID: {
            return {
                ...state,
                sid: action.sid
            }
        }

        case SET_TELEGRAM: {
            return {
                telegramLogin: action.telegramLogin
            }
        }

        case SET_API: {
            return {
                ...state,
                api: action.api
            }
        }

        case SET_AUTH: {
            return {
                ...state,
                isAuth: action.isAuth
            }
        }

        case SET_FETCH: {
            return {
                ...state,
                isFetching: action.isFetching
            }
        }

        case SET_AUTH_ERROR: {
            return {
                ...state,
                errorText: action.errorText
            }
        }

        default:
            return state
    }
}

//****ACTION CREATOR****//

export const setLoginAndPassword = (login, password) => ({
    type: SET_LOGIN_DATA,
    login,
    password
})

export const setSid = (sid) => ({
    type: SET_SID,
    sid
})

export const setTelegram = (telegramLogin) => ({
    type: SET_TELEGRAM,
    telegramLogin
})

export const setApi = (api) => ({
    type: SET_API,
    api
})

export const setFetching = (isFetching) => ({
    type: SET_FETCH,
    isFetching
})

export const setUserCreate = (userCreate) => ({
    type: SET_USER_CREATE,
    userCreate
})

export const setAuth = (isAuth) => ({
    type: SET_AUTH,
    isAuth
})

export const setErrorText = (errorText) => ({
    type: SET_AUTH_ERROR,
    errorText
})

//****THUNK****//

export const setLoginData = (params, rememberMe) => (dispatch) => { // выполняется один раз при логине в приложении
    LoginApi.doLogin(params)
        .then(data => {
            if (data.status === 'ok') {
                if (rememberMe) {
                    AuthHelper.setUserDataInLocalStorage(params.get("email"), params.get("password"))
                } else {
                    AuthHelper.setUserDataInSessionStorage(params.get("email"), params.get("password"))
                }

                AuthHelper.setSid(data.data.sid)

                Promise.all([
                    dispatch(setSid(data.data.sid)),
                    dispatch(setLoginAndPassword(params.get("email"), params.get("password"))),
                    dispatch(setErrorText(null)),
                    dispatch(getUserProfile(AuthHelper.getSid())),
                    dispatch(getObjects(AuthHelper.getSid())),
                ]).then(() => {
                    if (ObjectsHelper.getObjects().length > 0) {
                        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment({
                            hours: 23,
                            minutes: 59,
                            seconds: 59
                        }).format("YYYY-MM-DDThh:mm:ss")))
                    }
                }).then(() => {
                    dispatch(getUserInfoBlock(AuthHelper.getSid()))
                }).then(() => {
                    dispatch(setIsAuth(true))
                })

            } else {
                data.errors.map( error => {
                    if (error.name && error.name === 'WrongCredsException') {
                        // dispatch(setErrorText('Неверный Логин/Пароль'))
                        errorToast(error.msg)
                    } else {
                        // dispatch(setErrorText(AppConstants.TECHNICAL_WORKS))
                        errorToast(AppConstants.TECHNICAL_WORKS)
                    }
                })
            }
        })
        .catch(function (error) {
            let errorResponse = error.response
            let errorRequest = error.request
            let errorMessge = error.message
            let errorText = () => {
                return errorToast("Внимание! На сервере ведутся технические работы. В ближайшее время доступ к сервису восстановится. Приносим извинения за доставленные неудобства.")
            }

            if (error.response) {
                errorText()
                // Request made and server responded
                //alert(error.response)
            } else if (error.request) {
                errorText()
                // The request was made but no response was received
                console.log(error.request);
                ///alert(error.request)
                // debugger
            } else {
                // errorText()
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                // alert("Something happened in setting up the request that triggered an Error")
            }
        })
}

export const doLoginWithToken = (params) => (dispatch) => {
    LoginApi.doLoginWithToken(params)
        .then(data => {
            if (data.status === 'ok') {
                AuthHelper.setRole(data.data.role)
                let role = AuthHelper.getRole()

                Promise.all([
                    AuthHelper.setSid(data.data.sid)
                ]).then(() => {
                    if (AuthHelper.getRole() === "1") {
                        debugger
                        dispatch(initializeApplicationWithToken())
                    } else {
                        debugger
                        dispatch(initializeApplicationWithTokenAndRole())
                    }

                })

            } else {
                data.errors.map( error => {
                    if (error.name && error.name === 'WrongCredsException') {
                        errorToast('Неверный токен')
                    }
                })
            }
        })
}

export const doLoginWithTokenWithRole = (params, action) => (dispatch) => {
    LoginApi.doLoginWithToken(params)
        .then(data => {
            debugger
            if (data.status === 'ok') {

                // AuthHelper.setUserDataInSessionStorage(ROLE_LOGIN, ROLE_PASSWORD)

                Promise.all([
                    AuthHelper.setSid(data.data.sid)
                ]).then(() => {
                    dispatch(initializeApplicationWithTokenAndRole())
                })

            } else {
                data.errors.map( error => {
                    if (error.name && error.name === 'WrongCredsException') {
                        errorToast('Неверный токен')
                    }
                })
            }
        })
}

export const doRestoreWithToken = (params, action, props) => (dispatch) => {
    LoginApi.doLogin(params)
        .then(data => {
            if (data.status === 'ok') {
                // successToast("Пароль успешно измененён. Вы можете авторизоваться на сайте")
                AuthHelper.setSid(data.data.sid)
                /*if (action === true) {
                    dispatch(initializeApplication())
                }*/

            } else {
                data.errors.map( error => {
                    if (error.name && error.name === 'WrongCredsException') {
                        // dispatch(setErrorText('Неверный Логин/Пароль'))
                        errorToast('Неверный токен')
                    } else {
                        dispatch(setErrorText('Внимание! На сайте ведутся технические работы. В ближайшее время доступ к сервису восстановится. Приносим извинения за доставленные неудобства.'))
                    }
                })
            }
        })
}

export const registerNewUser = (params, fromAccess = false) => (dispatch) => {
    let email = params.get("email")
    let password = params.get("password")
    LoginApi.doRegister(params)
        .then(data => {
            if (data.status === 'ok') {
                let params = new URLSearchParams()
                params.append('email', email)
                params.append('password', password)
                if (!fromAccess) {
                    Promise.all([
                        successToast("Успешная регистрация")
                    ]).then(() => {
                        dispatch(setLoginData(params, true))
                    })
                } else {
                    successToast("Успешная регистрация")
                    dispatch(setUserCreate(false))
                }
            } else {
                dispatch(setUserCreate(true))
                if (data.errors[0].email !== undefined) {
                    errorToast(data.errors[0].email[0])
                }

                if (data.errors[0].phone !== undefined) {
                    errorToast(data.errors[0].phone[0])
                }
            }
        })
}

export const doLoginTelegram = (params) => (dispatch) => { // выполняется один раз при логине в приложении
    LoginApi.doLogin(params)
        .then(data => {
            if (data.status === 'ok') {

                AuthHelper.setSid(data.data.sid)

                Promise.all([
                    dispatch(setSid(data.data.sid)),
                ]).then(() => {
                    dispatch(setTelegram(true))
                })

            } else {
                data.errors.map( error => {
                    if (error.name && error.name === 'WrongCredsException') {
                        // dispatch(setErrorText('Неверный Логин/Пароль'))
                        errorToast(error.msg)
                    } else {
                        // dispatch(setErrorText(AppConstants.TECHNICAL_WORKS))
                        errorToast(AppConstants.TECHNICAL_WORKS)
                    }
                })
            }
        })
        .catch(function (error) {
            let errorResponse = error.response
            let errorRequest = error.request
            let errorMessge = error.message
            let errorText = () => {
                return errorToast("Внимание! На сервере ведутся технические работы. В ближайшее время доступ к сервису восстановится. Приносим извинения за доставленные неудобства.")
            }

            if (error.response) {
                errorText()
                // Request made and server responded
                //alert(error.response)
            } else if (error.request) {
                errorText()
                // The request was made but no response was received
                console.log(error.request);
                ///alert(error.request)
                // debugger
            } else {
                // errorText()
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                // alert("Something happened in setting up the request that triggered an Error")
            }
        })
}

export const setUserAccessDispatch = (userAccess) => (dispatch) => {
    dispatch(setUserCreate(userAccess))
}

export const restorePassword = (params) => (dispatch) => {
    LoginApi.doRestore(params)
        .then(data => {
            if (data.status === 'ok') {
                successToast("Инструкция по восстановлению пароля отправлена на указанный адрес")
            } else {

                data.errors.map( error => {

                    if (error.email) {
                        errorToast(error.email[0])
                    }

                    if (error.name && error.name === 'WrongCredsException') {
                        errorToast(error.msg)
                    } else {
                        dispatch(setErrorText('Внимание! На сайте ведутся технические работы. В ближайшее время доступ к сервису восстановится. Приносим извинения за доставленные неудобства.'))
                    }
                })
            }
        })
}

export const getSid = () => (dispatch) => { // получает новый СИД

    if (!sessionStorage.getItem("getNewSid")) {
        sessionStorage.setItem("getNewSid", true)
    } else {
        return
    }

    login = AuthHelper.getCurrentUser()
    password = AuthHelper.getCurrentUserPassword()

    if (!login && !password) {
        dispatch(setLogout())
        return
    }

    let params = new URLSearchParams()
    params.append('email', login)
    params.append('password', password)

    if (sessionStorage.getItem("getNewSid"))
    return LoginApi.doLogin(params)
        .then(data => {
            if (data.status === 'ok') {
                sessionStorage.removeItem("getNewSid")
                AuthHelper.setSid(data.data.sid)
                dispatch(setSid(data.data.sid))
                dispatch(setLoginAndPassword(login, password))
            } else {
            }
        })
}

export const setLogout = () => {
    return (
        (dispatch) => {
            localStorage.clear()
            sessionStorage.clear()
            window.location.reload()
        }
    )
}

export const setIsAuth = (isAuth) => {
    return (
        (dispatch) => {
            dispatch(setAuth(isAuth))
        }
    )
}

export default AuthReducer

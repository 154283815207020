
import * as AppConstants from "../utils/constants/AppConstants";
import * as axios from "axios";

export const PaymentApi = {
    payment_create(params) {
        return (
            axios
                .post(AppConstants.PAYMENT_CREATE_PATH, params)
                .then(response => {
                        return response.data
                    }
                )
        )
    }
}

import * as axios from "axios";
import * as AppConstants from "../utils/constants/AppConstants";

export const SendDataApi = {
    getSchedulers(sid, objectId) {
        return (
            axios
                .get(AppConstants.SCHEDULERS_URL_PATH + `?sid=${sid}&id=${objectId}`)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    getTemplates(sid) {
        return (
            axios
                .get(AppConstants.TEMPLATES_URL_PATH + `?sid=${sid}`)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    getNotifications(sid, objectId) {
        return (
            axios
                .get(AppConstants.NOTICES_URL_PATH + `?sid=${sid}&id=${objectId}`)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    postEditScheduler(params) {
        return (
            axios
                .post(AppConstants.SCHEDULERS_URL_PATH, params)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    postEditNotification(params) {
        return (
            axios
                .post(AppConstants.NOTICES_URL_PATH, params)
                .then(response => {
                        return response.data
                    }
                )

        )
    }
}

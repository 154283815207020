import React from "react";
import "./Spinner.scss";
import {Spinner} from "reactstrap";

const PageSpinner = () => {
  return (
    <div className="fallback-spinner">
      <div className="custom_loading custom_spinner">
          <Spinner color="info" />
      </div>
    </div>
  );
};
export default PageSpinner;

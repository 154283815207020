import React, {Suspense, lazy} from "react";
import ReactDOM from "react-dom";
import "./assets/scss/style.scss";
import "react-datetime/css/react-datetime.css";
import PageSpinner from "./viewsContainers/spinner/PageSpinner";
import {configureStore} from "./redux/Store";
import {Router} from "react-router-dom";
import {createBrowserHistory} from "history";
import {Provider} from "react-redux";
import "./utils/Interceptors"


const App = lazy(() =>
    new Promise((resolve) => {
        setTimeout(() => resolve(import("./app")), 0);
    })
)


ReactDOM.render(
    <Suspense fallback={<PageSpinner/>}>
        <Provider store={configureStore()}>
            <Router history={createBrowserHistory({basename: '/'})}>
                {/*<ClearCacheProvider auto={true}>*/}
                {/*    <App/>*/}
                {/*</ClearCacheProvider>*/}
                <App/>
            </Router>
        </Provider>
    </Suspense>,
    document.getElementById("root")
);

import {objectsApi} from "../../api/ObjectsApi";
import {initializeApplication} from "./AppReducer";
import {ObjectsHelper} from "../../helpers/ObjectsHelper";
import {getSid} from "./AuthReducer";
import {AuthHelper} from "../../helpers/AuthHelper";
import {errorToast, successToast} from "../../utils/Toasts";
import {SendDataApi} from "../../api/SendDataApi";
import {getNotifications, getSchedulers, setSuccessSchedulerTh} from "./SendDataReducer";
import * as AppConstants from "../../utils/constants/AppConstants";
import moment from "moment";
import {getMeterData, getMeters} from "./MetersReducer";
import {clearEventsT} from "./JournalReducer";
import {getHost, returnToHomePage} from "../../utils/Settings";
import {MetersHeler} from "../../helpers/MetersHelper";
import {meterTypeName} from "../../components/dashboard/utils/DashboardUtils";
import _ from 'lodash'

const SET_OBJECT_ID = 'SET_OBJECT_ID'
const SET_OBJECTS = 'SET_OBJECTS'
const SET_TARIFFS = 'SET_TARIFFS'
const SET_FETCH = 'SET_FETCH'
const SET_OBJECT_CREATE = 'SET_OBJECT_CREATE'
const SET_ACCESS = 'SET_ACCESS'
const SET_SERVICES = 'SET_SERVICES'
const SET_PAYMENTS = 'SET_PAYMENTS'
const CLEAR_TARIFS = 'CLEAR_TARIFS'
const CLEAR_PAYMENTS = 'CLEAR_PAYMENTS'
const SET_PAYMENTS_COUNT = 'SET_PAYMENTS_COUNT'
const SET_TARIFF_PLUGIN = 'SET_TARIFF_PLUGIN'
const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'

let initialState = {
    listObjects: null,
    listObjectAccess: [],
    isFetching: false,
    objectId: null,
    objectCreate: false,
    tariffs: null,
    services: null,
    payments: [],
    paymentsCount: 0,
    pluginTariff: false,
    currentPage: 2
}

const ObjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_OBJECTS: {
            return {
                ...state,
                listObjects: [...action.object]
            }
        }

        case SET_TARIFF_PLUGIN: {
            return {
                ...state,
                pluginTariff: action.pluginTariff
            }
        }

        case SET_TARIFFS: {
            return {
                ...state,
                tariffs: [...action.tariffs]
            }
        }

        case SET_PAYMENTS: {
            let pState = state.payments
            if (pState === undefined) {
                return {
                    ...state,
                    payments: [...action.payments]
                }
            }
            return {
                ...state,
                payments: [...state.payments, ...action.payments]
            }
        }

        case SET_PAYMENTS_COUNT: {
            return {
                ...state,
                paymentsCount: action.paymentsCount
            }
        }

        case SET_CURRENT_PAGE: {
            return {
                ...state,
                currentPage: action.currentPage
            }
        }

        case SET_SERVICES: {
            return {
                ...state,
                services : [...action.services]
            }
        }

        case SET_FETCH: {
            return {
                ...state,
                isFetching: action.isFetching
            }
        }

        case SET_OBJECT_ID: {
            return {
                ...state,
                objectId: action.objectId
            }
        }

        case SET_OBJECT_CREATE: {
            return {
                ...state,
                objectCreate: action.objectCreate
            }
        }

        case SET_ACCESS: {
            return {
                ...state,
                listObjectAccess: [...action.access]
            }
        }

        case CLEAR_TARIFS: {
            return {
                tariffs: null
            }
        }

        default: {
            return state
        }
    }
}

//****ACTION CREATOR****//

export const setObjectId = (objectId) => ({
    type: SET_OBJECT_ID,
    objectId
})

export const setTariffs = (tariffs) => ({
    type: SET_TARIFFS,
    tariffs
})

export const setPayments = (payments) => ({
    type: SET_PAYMENTS,
    payments
})

export const setPluginTariff = (pluginTariff) => ({
    type: SET_TARIFF_PLUGIN,
    pluginTariff
})

export const setPaymentsCount = (paymentsCount) => ({
    type: SET_PAYMENTS_COUNT,
    paymentsCount
})

export const setCurrentPageTransactionsT = (currentPage) => ({
    type: SET_CURRENT_PAGE, currentPage
})

export const setServices = (services) => ({
    type: SET_SERVICES,
    services
})

export const setUserObjects = (object) => ({
    type: SET_OBJECTS,
    object
})

export const setFetching = (isFetching) => ({
    type: SET_FETCH,
    isFetching
})

export const setObjectCreate = (objectCreate) => ({
    type: SET_OBJECT_CREATE,
    objectCreate
})

export const setObjectAccess = (access) => ({
    type: SET_ACCESS,
    access
})

export const clearTariffsA = () => ({
    type: CLEAR_TARIFS
})

export const clearPaymentsA = () => ({
    type: CLEAR_PAYMENTS
})

//****THUNK****//

export const setCurrentPageTransactions = (page) => {
    return (
        (dispatch) => {
            dispatch(setCurrentPageTransactionsT(page))
        }
    )
}

export const clearTariffsT = () => {
    return (
        (dispatch) => {
            dispatch(clearTariffsA())
        }
    )
}

export const clearPaymentsT = () => {
    return (
        (dispatch) => {
            dispatch(clearPaymentsA())
        }
    )
}

export const setIsTariffPlugin = (isPlugged) => {
    return (
        (dispatch) => {
            dispatch(setPluginTariff(isPlugged))
        }
    )
}

export const getObjects = (sid) => (dispatch) => {
    return objectsApi.getObjects(sid)
        .then(data => {
                if (data.status === 'ok') {
                    let responseObjects = data.data.objects
                    if (responseObjects === undefined) responseObjects = []
                    ObjectsHelper.setObjects(responseObjects)
                    dispatch(setUserObjects(responseObjects))

                    if (!ObjectsHelper.getObjectId() && responseObjects.length > 0) {
                        dispatch(setObjectIdThunk(responseObjects[0].id))
                    }

                    if (!ObjectsHelper.getObjectItem() && responseObjects.length !== 0) {
                        ObjectsHelper.setObjectItem(responseObjects[0])
                    }

                    if (ObjectsHelper.getObjectItem() && responseObjects.length !== 0) { // сравниваю загружаемый объект с тем, что хранится в памяти. Если есть различия, обновляю
                        responseObjects.map((item, i) => {
                            if (item.id === ObjectsHelper.getObjectItem().id) {
                                let a = item
                                let b = ObjectsHelper.getObjectItem()
                                let compareObjects = _.isEqual(a, b)
                                if (!compareObjects) {
                                    localStorage.removeItem(AppConstants.OBJECT_ITEM)
                                    ObjectsHelper.setObjectItem(responseObjects[i])
                                }
                            }
                        })
                    }

                    if (ObjectsHelper.getObjectId() && responseObjects.length !== 0) { // сравниваю загружаемый объект с тем, что хранится в памяти. Если есть различия, обновляю
                        responseObjects.map((item, i) => {
                            if (item.id === ObjectsHelper.getObjectId) {
                                let a = item
                                let b = ObjectsHelper.getObjectItem()
                                let compareObjects = _.isEqual(a, b)
                                if (!compareObjects) {
                                    localStorage.removeItem(AppConstants.OBJECT_ITEM)
                                    ObjectsHelper.setObjectItem(responseObjects[i])
                                }
                            }
                        })
                    }

                    if (responseObjects.length === 0) {
                        document.getElementById('topName').innerHTML = ""
                        document.getElementById('topNameDrop').innerHTML = ""
                    }

                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        Promise.all([dispatch(getSid())]).then(() => {
                            getObjects(AuthHelper.getSid())
                        })
                    }
                }
            }
        )
        .catch(function (error) {
            let errorResponse = error.response
            let errorRequest = error.request
            let errorMessge = error.message
            let errorText = () => {
                return errorToast("Внимание! На сервере ведутся технические работы. В ближайшее время доступ к сервису восстановится. Приносим извинения за доставленные неудобства.")
            }

            if (error.response) {
                errorText()
                // Request made and server responded
                //alert(error.response)
            } else if (error.request) {
                errorText()
                // The request was made but no response was received
                console.log(error.request);
                ///alert(error.request)
                // debugger
            } else {
                // errorText()
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                // alert("Something happened in setting up the request that triggered an Error")
            }
        })
}

export const getObjectItem = (sid, object_id) => (dispatch) => {
    let mObjId = object_id

    return objectsApi.getObjectItem(sid, object_id)
        .then(data => {
            debugger
                if (data.status === 'ok') {
                    let responseItem = data.data
                    let responseObjects = []

                    if (responseItem.length !== 0) {

                        ObjectsHelper.setObjectItem(responseItem)

                        responseObjects.push({...responseItem})
                        ObjectsHelper.setObjects(responseObjects)

                        debugger

                    }

                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        Promise.all([dispatch(getSid())]).then(() => {
                            getObjectTariffs(AuthHelper.getSid(), mObjId)
                        })
                    }
                }
            }
        )
        .catch(function (error) {
            let errorResponse = error.response
            let errorRequest = error.request
            let errorMessge = error.message

            let errorText = () => {
                return errorToast("Внимание! На сервере ведутся технические работы. В ближайшее время доступ к сервису восстановится. Приносим извинения за доставленные неудобства.")
            }

            if (error.response) {
                errorText()
                // Request made and server responded
                //alert(error.response)
            } else if (error.request) {
                errorText()
                // The request was made but no response was received
                console.log(error.request);
                ///alert(error.request)
                // debugger
            } else {
                errorText()
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                // alert("Something happened in setting up the request that triggered an Error")
            }
        })
}

export const getObjectTariffs = (sid, object_id) => (dispatch) => {
    let mObjId = object_id
    return objectsApi.getObjectTariffs(sid, object_id)
        .then(data => {
                if (data.status === 'ok') {
                    let responseTariffs = data.data.tariffs
                    if (responseTariffs === undefined) responseTariffs = []

                    Promise.all([
                        dispatch(setTariffs(responseTariffs))
                    ]).then(() => {
                        dispatch(getObjectServices(AuthHelper.getSid(), mObjId))
                    }).then(() => {
                        dispatch(getObjectPayments(AuthHelper.getSid(), mObjId, AppConstants.PAYMENT_PAGE, AppConstants.PAYMENT_STEP))
                    })

                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        Promise.all([dispatch(getSid())]).then(() => {
                            getObjectTariffs(AuthHelper.getSid(), mObjId)
                        })
                    }
                }
            }
        )
        .catch(function (error) {
            let errorResponse = error.response
            let errorRequest = error.request
            let errorMessge = error.message

            let errorText = () => {
                return errorToast("Внимание! На сервере ведутся технические работы. В ближайшее время доступ к сервису восстановится. Приносим извинения за доставленные неудобства.")
            }

            if (error.response) {
                errorText()
                // Request made and server responded
                //alert(error.response)
            } else if (error.request) {
                errorText()
                // The request was made but no response was received
                console.log(error.request);
                ///alert(error.request)
                // debugger
            } else {
                errorText()
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                // alert("Something happened in setting up the request that triggered an Error")
            }
        })
}

export const getObjectServices = (sid, object_id) => (dispatch) => {
    let mObjId = object_id
    return objectsApi.getObjectServices(sid, object_id)
        .then(data => {
                if (data.status === 'ok') {
                    let responseServices = data.data.services
                    if (responseServices === undefined) responseServices = []
                    // ObjectsHelper.setObjects(responseObjects)
                    dispatch(setServices(responseServices))
                    //
                    // if (!ObjectsHelper.getObjectId() && responseObjects.length > 0) {
                    //     dispatch(setObjectIdThunk(responseObjects[0].id))
                    // }

                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        Promise.all([dispatch(getSid())]).then(() => {
                            getObjectServices(AuthHelper.getSid(), mObjId)
                        })
                    }
                }
            }
        )
        .catch(function (error) {
            let errorResponse = error.response
            let errorRequest = error.request
            let errorMessge = error.message
            let errorText = () => {
                return errorToast("Внимание! На сервере ведутся технические работы. В ближайшее время доступ к сервису восстановится. Приносим извинения за доставленные неудобства.")
            }

            if (error.response) {
                errorText()
                // Request made and server responded
                //alert(error.response)
            } else if (error.request) {
                errorText()
                // The request was made but no response was received
                console.log(error.request);
                ///alert(error.request)
                // debugger
            } else {
                // errorText()
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                // alert("Something happened in setting up the request that triggered an Error")
            }
        })
}

export const getObjectPayments = (sid, object_id, page, step) => (dispatch) => {

    let mPage = page
    let mStep = step
    let mObjId = object_id
    return objectsApi.getObjectPayments(sid, mObjId, mPage, mStep)
        .then(data => {

                if (data.status === 'ok') {
                    let responsePayments = data.data.transactions
                    if (responsePayments === undefined) responsePayments = []
                    let totalCount
                    if (responsePayments.length !== 0) totalCount = data.data.count
                    Promise.all([
                        dispatch(setPayments(responsePayments))
                    ]).then(() => {
                        dispatch(setPaymentsCount(totalCount))
                    })

                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        Promise.all([dispatch(getSid())]).then(() => {
                            getObjectPayments(AuthHelper.getSid(), mObjId, mPage, mStep)
                        })
                    }

                    if (data.errors[0].name === "BadRequest") {
                        // errorToast(data.errors[0].msg)
                    }

                    if (data.errors[0].name === "SystemError") {
                        // errorToast("Неожиданная ошибка при получении списка транзакций")
                    }
                }
            }
        )
        .catch(function (error) {
            let errorResponse = error.response
            let errorRequest = error.request
            let errorMessge = error.message
            let errorText = () => {
                return errorToast("Внимание! На сервере ведутся технические работы. В ближайшее время доступ к сервису восстановится. Приносим извинения за доставленные неудобства.")
            }

            if (error.response) {
                errorText()
                // Request made and server responded
                //alert(error.response)
            } else if (error.request) {
                errorText()
                // The request was made but no response was received
                console.log(error.request);
                ///alert(error.request)
                // debugger
            } else {
                // errorText()
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                // alert("Something happened in setting up the request that triggered an Error")
            }
        })
}

export const postPluginTariff = (params) => {
    let param = params
    return (
        (dispatch) => {
            objectsApi.pluginTariff(param)
                .then(data => {
                    if (data.status === 'ok') {
                        Promise.all([
                            dispatch(getObjectTariffs(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                        ]).then(() => {
                            dispatch(setIsTariffPlugin(true))
                        })

                    } else {

                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(postPluginTariff(param))
                            })
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }
                        if (data.errors[0].name === "AbstractException") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }
                    }
                })
        }
    )
}

export const postPluginService = (params) => {
    let param = params
    return (
        (dispatch) => {
            objectsApi.pluginService(param)
                .then(data => {
                    if (data.status === 'ok') {
                        let textToast
                        let servicePlug = param.get("plug")
                        let serviceUnplug = param.get("unplug")
                        if (servicePlug) textToast = "Услуга подключена"
                        else if (serviceUnplug) textToast = "Услуга отключена"
                        successToast(textToast)
                        dispatch(getObjectServices(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                    } else {

                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(postPluginService(param))
                            })
                        }

                        if (data.errors[0].name === "AbstractException") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }
                    }
                })
        }
    )
}

export const createObject = (params) => {
    let param = params
    return (
        (dispatch) => {
            objectsApi.createObjectApi(param)
                .then(data => {

                    if (data.status === 'ok') {
                        successToast("Объект успешно создан")
                        ObjectsHelper.setObjectAdress(param.get("city") + ', ' + param.get("street") + ', ' + (param.get("number") !== "" ? param.get("number") : ""))
                        dispatch(setObjectIdThunk(data.data.id))

                        Promise.all([
                            dispatch(getObjects(AuthHelper.getSid()))
                        ]).then(() => {
                            dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment().format("YYYY-MM-DDThh:mm:ss")))
                        }).then(() => {
                            returnToHomePage()
                        })
                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            Promise.all([
                                dispatch(getSid())
                            ]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(createObject(param))
                            })
                        }

                        if (data.errors[0].name === "AbstractException") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].install_inn) {
                            errorToast(data.errors[0].install_inn[0])
                        }

                        if (data.errors[0].building) {
                            errorToast("Дом: " + data.errors[0].building[0])
                        }

                        if (data.errors[0].management_inn) {
                            errorToast(data.errors[0].management_inn[0])
                        }

                        if (data.errors[0].personal_account) {
                            errorToast(data.errors[0].personal_account[0])
                        }

                        if (data.errors[0].account_id) {
                            errorToast(data.errors[0].account_id[0])
                        }

                        if (data.errors[0].number) {
                            errorToast(data.errors[0].number[0])
                        }
                    }
                })
        }
    )
}

export const deleteObjects = (sid, id) => (dispatch) => {
    let mId = id
    return objectsApi.deleteObjects(AuthHelper.getSid(), mId)
        .then(data => {
                if (data.status === 'ok') {
                    localStorage.removeItem(AppConstants.OBJECT_ADRESS)
                    localStorage.removeItem(AppConstants.OBJECT_ID)
                    dispatch(clearEventsT())
                    successToast("Объект успешно удалён")
                    Promise.all([
                        dispatch(getObjects(AuthHelper.getSid()))
                    ]).then(() => {
                        dispatch(getMeterData(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment().format("YYYY-MM-DDThh:mm:ss")))
                    })
                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        let promiseGetSid = dispatch(getSid())
                        Promise.all([promiseGetSid]).then(() => {
                            dispatch(deleteObjects(AuthHelper.getSid(), mId))
                        })
                    }

                    if (data.errors[0].name === "PermissionDenied") {
                        errorToast(data.errors[0].msg)
                    }

                    if (data.errors[0].name === "AbstractException") {
                        errorToast(data.errors[0].msg)
                    }
                }
            }
        )
}

export const getObjectAccess = (sid, objectId) => (dispatch) => {
    return objectsApi.getObjectAccess(sid, objectId)
        .then(data => {
                if (data.status === 'ok') {
                    let response = data.data
                    if (response === undefined) response = []
                    dispatch(setObjectAccess(response))
                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        Promise.all([
                            dispatch(getSid())
                        ]).then(() => {
                            getObjectAccess(AuthHelper.getSid(), ObjectsHelper.getObjectId())
                        })
                    }
                }
            }
        )
}

export const postEditAccess = (params) => {
    let param = params
    return (
        (dispatch) => {
            objectsApi.editObjectAccess(param)
                .then(data => {
                    if (data.status === 'ok') {
                        sessionStorage.setItem("createAccess1", "false")
                        sessionStorage.removeItem("createAccess")
                        successToast(param.get("id") === null ? "Доступ успешно предоставлен" : "Доступ успешно отредактирован")
                        dispatch(getObjectAccess(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                    } else {
                        sessionStorage.setItem("createAccess1", "true")
                        sessionStorage.setItem("createAccess", "true")

                        if (data.errors[0].email !== undefined) {
                            errorToast(data.errors[0].email[0])
                        }

                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(postEditAccess(param))
                            })
                        }

                        if (data.errors[0].name === "AbstractException") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "PermissionDenied") {
                            errorToast(data.errors[0].msg)
                        }

                        if (data.errors[0].name === "SystemError") {
                            errorToast("Критическая ошибка")
                        }
                    }
                })
        }
    )
}

export const deleteObjectAccess = (params) => (dispatch) => {
    let param = params
    return objectsApi.editObjectAccess(param)
        .then(data => {
                if (data.status === 'ok') {
                    successToast("Доступ пользователя успешно удалён")
                    dispatch(getObjectAccess(AuthHelper.getSid(), ObjectsHelper.getObjectId()))
                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        Promise.all([
                            dispatch(getSid())
                        ]).then(() => {
                            dispatch(deleteObjectAccess(param))
                        })
                    }

                    if (data.errors[0].name === "PermissionDenied") {
                        errorToast(data.errors[0].msg)
                    }

                    if (data.errors[0].name === "AbstractException") {
                        errorToast(data.errors[0].msg)
                    }
                }
            }
        )
}


export const setObjectIdThunk = (id) => (dispatch) => {
    ObjectsHelper.setObjectId(id)
    dispatch(setObjectId(ObjectsHelper.getObjectId()))
}

export const setObjectCreateThunk = (isCreate) => (dispatch) => {
    dispatch(setObjectCreate(isCreate))
}

export default ObjectReducer

import {ProfileApi} from "../../api/ProfileApi"
import * as AppConstants from "../../utils/constants/AppConstants";
import {LoginApi} from "../../api/LoginApi";
import {initializeApplication, initializedSuccess, setNeedUpdate, setUpdatePage} from "./AppReducer";
import {getSid} from "./AuthReducer";
import {AuthHelper} from "../../helpers/AuthHelper";
import {errorToast, successToast, warnToast} from "../../utils/Toasts";

const SET_USER_PROFILE = 'SET_USER_PROFILE'

let login
let password

let initialState = {
    data: null,
    errors: null,
    status: null
}

const ProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_PROFILE: {
            return {
                ...state,
                data: action.profile
            }
        }

        default: {
            return state
        }
    }
}

export const setUserProfile = (profile) => ({
    type: SET_USER_PROFILE, profile
})

export const changePassword = (profile) => ({
    type: SET_USER_PROFILE, profile
})

export const getUserProfile = (sid) => {

    return (
        (dispatch) => {
            ProfileApi.getProfile(sid)
                .then(data => {
                    if (data.status === 'ok') {
                        dispatch(setUserProfile(data))
                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            Promise.all([
                                dispatch(getSid())
                            ]).then(() => {
                                dispatch(getUserProfile(AuthHelper.getSid()))
                            })
                        }
                    }
                })
        }
    )
}

export const setUserProfileT = (params) => {
    let param = params
    return (
        (dispatch) => {
            ProfileApi.setProfile(param)
                .then(data => {
                    if (data.status === 'ok') {
                        successToast("Данные успешно сохранены")
                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(setUserProfile(param))
                            })
                        }

                        if (data.errors[0].email !== undefined) {
                            errorToast(data.errors[0].email)
                        }

                    }
                })
        }
    )
}

export const changePasswordT = (params) => {
    let param = params
    return (
        (dispatch) => {
            ProfileApi.setNewPassword(param)
                .then(data => {
                    debugger
                    if (data.status === 'ok') {
                        successToast("Пароль успешно изменен")
                    } else {
                        if (data.errors[0].name === "WrongSIDException") {
                            params.delete('sid')
                            let promiseGetSid = dispatch(getSid())
                            Promise.all([promiseGetSid]).then(() => {
                                param.append('sid', AuthHelper.getSid());
                                dispatch(changePasswordT(param))
                            })
                        }
                    }
                })
        }
    )
}

export default ProfileReducer

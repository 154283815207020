
import * as AppConstants from "../utils/constants/AppConstants";
import * as axios from "axios";
import {
    CONTROLLER_SETTINGS_URL_PATH, GET_METER_CHANNELS_URL_PATH,
    GET_OBJECT_ITEM,
    GET_SENSOR_CHANNELS_URL_PATH
} from "../utils/constants/AppConstants";

export const objectsApi = {
    getObjects(sid) {
        return (
            axios
                .get(AppConstants.OBJECTS_URL_PATH + `?sid=${sid}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    getObjectItem(sid, object_id) {
        return (
            axios
                .get(AppConstants.GET_OBJECT_ITEM + `?sid=${sid}&id=${object_id}`)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    getObjectTariffs(sid, object_id) {
        return (
            axios
                .get(AppConstants.GET_TARIFFS_PATH + `?sid=${sid}&object_id=${object_id}`)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    pluginTariff(params) {
        return (
            axios
                .post(AppConstants.GET_TARIFFS_PATH, params)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    pluginService(params) {
        return (
            axios
                .post(AppConstants.GET_SERVICES_PATH, params)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    getObjectServices(sid, object_id) {
        return (
            axios
                .get(AppConstants.GET_SERVICES_PATH + `?sid=${sid}&object_id=${object_id}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    getObjectPayments(sid, object_id, page, step) {
        return (
            axios
                .get(AppConstants.GET_PAYMENTS_PATH + `?sid=${sid}&id=${object_id}&page=${page}&step=${step}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    deleteObjects(sid, id) {
        return (
            axios
                .get(AppConstants.DELETE_OBJECT_PATH + `?sid=${sid}&id=${id}`)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    getR8Device(sid, sn) {
        return (
            axios
                .get(AppConstants.GET_SENSOR_CHANNELS_URL_PATH + `?sid=${sid}&sn=${sn}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    getSettingsChannel(sid, id) {
        return (
            axios
                .get(AppConstants.GET_METER_CHANNELS_URL_PATH + `?sid=${sid}&id=${id}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    setSettingsChannel(params) {
        return (
            axios
                .post(AppConstants.GET_METER_CHANNELS_URL_PATH, params)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    getDevice(sid, sn) {
        return (
            axios
                .get(AppConstants.GET_SENSOR_URL_PATH + `?sid=${sid}&sn=${sn}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    getBattery(sid, sn, start, finish) {
        return (
            axios
                .get(AppConstants.GET_CONTROLLER_BATTERY_URL_PATH + `?sid=${sid}&sn=${sn}&start=${start}&finish=${finish}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    addDeviceToController(params) {
        return (
            axios
                .post(AppConstants.GET_SENSOR_URL_PATH, params)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    addDeviceToControllerR8(params) {
        return (
            axios
                .post(AppConstants.GET_SENSOR_CHANNELS_URL_PATH, params)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    getMeters(sid, id, date) {
        return (
            axios
                .get(AppConstants.METERS_URL_PATH + `?sid=${sid}&id=${id}&date=${date}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    getControllerSettings(sid, sn) {
        return (
            axios
                .get(AppConstants.CONTROLLER_SETTINGS_URL_PATH + `?sid=${sid}&sn=${sn}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    updateController(params) {
        return (
            axios
                .post(AppConstants.CONTROLLER_SETTINGS_URL_PATH, params)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    deleteMeters(sid, id) {
        return (
            axios
                .get(AppConstants.DELETE_METER_PATH + `?sid=${sid}&id=${id}`)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    updateMeter(params) {
        return (
            axios
                .post(AppConstants.UPDATE_METER_PATH, params)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    controlCommand(params) {
        return (
            axios
                .post(AppConstants.COMMAND_SENSOR_PATH, params)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    getReleaseNotes(hw) {
        return (
            axios
                .get(AppConstants.RELEASE_NOTES_PATH + `?hw=${hw}&html=${true}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    createObjectApi(params) {
        return (
            axios
                .post(AppConstants.CREATE_OBJECT_PATH, params)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    getObjectAccess(sid, objectId) {
        return (
            axios
                .get(AppConstants.OBJECT_ACCESS_PATH + `?sid=${sid}&id=${objectId}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    editObjectAccess(params) {
        return (
            axios
                .post(AppConstants.OBJECT_ACCESS_PATH, params)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    async getMetersGraphic(sid, id, dateStart, dateEnd, groupBy, absolute) {
        let absoluteParam = absolute === "true" ? "&absolute=1" : ""
        return (
            await axios
                .get(AppConstants.GRAPHIC_METER_PATH + `?sid=${sid}&id=${id}&start=${dateStart}&finish=${dateEnd}&group=${groupBy}${absoluteParam}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    async getMetersControl(sid, id, dateStart, dateEnd) {
        return (
            await axios
                .get(AppConstants.GRAPHIC_METER_PATH + `?sid=${sid}&id=${id}&start=${dateStart}&finish=${dateEnd}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    controllerMoveApi(params) {
        return (
            axios
                .post(AppConstants.COMMAND_SENSOR_MOVE_PATH, params)
                .then(response => {
                        return response.data
                    }
                )

        )
    }
    ,
    controllerClearApi(sid, sn) {
        return (
            axios
                .get(AppConstants.COMMAND_SENSOR_CLEAR_PATH + `?sid=${sid}&sn=${sn}`)
                .then(response => {
                        return response.data
                    }
                )

        )
    }
    ,
    controllerDeleteApi(sid, sn) {
        return (
            axios
                .get(AppConstants.COMMAND_SENSOR_DELETE_PATH + `?sid=${sid}&sn=${sn}`)
                .then(response => {
                        return response.data
                    }
                )
        )
    }
}

import * as AppConstants from "../utils/constants/AppConstants";
import * as axios from "axios";
import instance from "./Instance";

export const LoginApi = {
    doLogin(params) {
        return (
            axios
                .post(AppConstants.LOGIN_URL_PATH, params)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    doLoginTelegram(params) {
        return (
            axios
                .post(AppConstants.LOGIN_URL_PATH, params)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    doLoginWithToken(params) {
        return (
            axios
                .post(AppConstants.LOGIN_URL_PATH, params)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    doRegister(params) {
        return (
            axios
                .post(AppConstants.REGISTER_PATH, params)
                .then(response => {
                        return response.data
                    }
                )
        )
    },
    doRestore(params) {
        return (
            axios
                .post(AppConstants.RECOVERY_PASSWORD_PATH, params)
                .then(response => {
                        return response.data
                    }
                )
        )
    }
}

import {objectsApi, objectsApi as ObjectsApi} from "../../api/ObjectsApi";
import {getSid, setLogout} from "./AuthReducer";
import {AuthHelper} from "../../helpers/AuthHelper";
import {ObjectsHelper} from "../../helpers/ObjectsHelper";
import moment from "moment";
import * as AppConstants from "../../utils/constants/AppConstants";
import {errorToast, successToast} from "../../utils/Toasts";
import {getJournal} from "./JournalReducer";
import {MetersHeler} from "../../helpers/MetersHelper";
import * as axios from "axios";
import {meterTypeName} from "../../components/dashboard/utils/DashboardUtils";
import {setMenuHide, setMinutes} from "../../utils/Settings";
import {getObjects, getObjectTariffs} from "./ObjectReducer";
import {setEditIsLoaded} from "./ChannelsReducer";

const SET_SENSORS = 'SET_SENSORS'
const SET_METERS = 'SET_METERS'
const SET_FETCH = 'SET_FETCH'
const SET_RELEASE_NOTES = 'SET_RELEASE_NOTES'
const SET_METER_GRAPHIC = 'SET_METER_GRAPHIC'
const SET_EVENT_GRAPHIC = 'SET_EVENT_GRAPHIC'
const SET_EVENTS_ARRAY = 'SET_EVENTS_ARRAY'
const SET_GRAPHICS_ARRAY = 'SET_GRAPHICS_ARRAY'
const SET_LINK = 'SET_LINK'
const SET_GET_DEVICES = 'SET_GET_DEVICES'
const SET_ADD_DEVICES = 'SET_ADD_DEVICES'
const SET_ERROR_DEVICES = 'SET_ERROR_DEVICES'
const SET_GET_R8_DEVICES = 'SET_GET_R8_DEVICES'
const SET_ADD_R8_DEVICES = 'SET_ADD_R8_DEVICES'
const SET_ERROR_R8_DEVICES = 'SET_ERROR_R8_DEVICES'
const SET_BATTERY = 'SET_BATTERY'
const SET_METER_SETTINGS = 'SET_METER_SETTINGS'
const SET_READING_SETTINGS = 'SET_READING_SETTINGS'
const SET_GRAPHICS_SETTINGS = 'SET_GRAPHICS_SETTINGS'
const SET_EVENTS_SETTINGS = 'SET_EVENTS_SETTINGS'
const SET_DISABLE_BY_BALANCE = 'SET_DISABLE_BY_BALANCE'
const SET_CONTROLLER_SETTINGS = 'SET_CONTROLLER_SETTINGS'
const SET_CHANNELS_ARRAY = 'SET_CHANNELS_ARRAY'

let initialState = {
    sensors: null,
    sensorsForDashboardOnly: null,
    meters: null,
    fetch: false,
    releaseNotes: null,
    graphics: null,
    graphicsDashboard: null,
    events: null,
    eventsArray: [],
    link: "#",
    devicesR8: null,
    addDevicesR8Const: false,
    devices: null,
    addDevicesConst: false,
    errorDevice: null,
    battery: null,
    meterSettings: null,
    readingSettings: null,
    graphicsSettings: null,
    eventsSettings: null,
    disableByBalance: false,
    settings: null,
    channelsArray: null,
}

let mDate

const MeterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DISABLE_BY_BALANCE: {
            return {
                ...state,
                channelsArray: action.channelsArray
            }
        }
        case SET_CHANNELS_ARRAY: {
            return {
                ...state,
                disableByBalance: action.disableByBalance
            }
        }
        case SET_EVENTS_SETTINGS: {
            return {
                ...state,
                eventsSettings: action.eventsSettings
            }
        }

        case SET_GRAPHICS_SETTINGS: {
            return {
                ...state,
                graphicsSettings: action.graphicsSettings
            }
        }

        case SET_METER_SETTINGS: {
            return {
                ...state,
                meterSettings: action.meterSettings
            }
        }

        case SET_READING_SETTINGS: {
            return {
                ...state,
                readingSettings: action.readingSettings
            }
        }

        case SET_GET_DEVICES: {
            return {
                ...state,
                devices: action.devices
            }
        }

        case SET_ADD_DEVICES: {
            return {
                ...state,
                addDevicesConst: action.addDevicesConst
            }
        }

        case SET_ADD_R8_DEVICES: {
            return {
                ...state,
                addDevicesR8Const: action.addDevicesR8Const
            }
        }

        case SET_GRAPHICS_ARRAY: {
            return {
                ...state,
                graphicsDashboard: [...action.graphicsDashboard]
            }
        }

        case SET_ERROR_DEVICES: {
            return {
                ...state,
                errorDevice: action.errorDevice
            }
        }

        case SET_LINK: {
            return {
                ...state,
                link: action.link
            }
        }

        case SET_SENSORS: {
            return {
                ...state,
                sensors: [...action.sensors]
            }
        }

        case SET_BATTERY: {
            return {
                ...state,
                battery: {...action.battery}
            }
        }

        case SET_METER_GRAPHIC: {
            return {
                ...state,
                graphics: {...action.graphics}
            }
        }

        case SET_EVENT_GRAPHIC: {
            return {
                ...state,
                events: {...action.events}
            }
        }

        case SET_CONTROLLER_SETTINGS: {
            return {
                ...state,
                settings: {...action.settings}
            }
        }

        case SET_EVENTS_ARRAY: {
            return {
                ...state,
                eventsArray: [...action.eventsArray]
            }
        }

        case SET_RELEASE_NOTES: {
            return {
                ...state,
                releaseNotes: [action.releaseNotes]
            }
        }

        case SET_METERS: {
            return {
                ...state,
                meters: [...action.meters]
            }
        }

        case SET_FETCH: {
            return {
                ...state,
                fetch: action.fetch
            }
        }

        // case SET_GET_R8_DEVICES: {
        //     debugger
        //     return {
        //         ...state,
        //         devicesR8: [action.devicesR8]
        //     }
        // }

        default:
            return state
    }
}

//****ACTION CREATOR****//\\

export const setEventsSettings = (eventsSettings) => ({
    type: SET_EVENTS_SETTINGS,
    eventsSettings
})

export const setDisableByBalanceA = (disableByBalance) => ({
    type: SET_DISABLE_BY_BALANCE,
    disableByBalance
})

export const setGraphicSettings = (graphicsSettings) => ({
    type: SET_GRAPHICS_SETTINGS,
    graphicsSettings
})

export const setMeterSettings = (meterSettings) => ({
    type: SET_METER_SETTINGS,
    meterSettings
})

export const setReadingSettings = (readingSettings) => ({
    type: SET_READING_SETTINGS,
    readingSettings
})

export const setGetDevices = (devices) => ({
    type: SET_GET_DEVICES,
    devices
})

// export const setGetR8Devices = (devicesR8) => ({
//     type: SET_GET_R8_DEVICES,
//     devicesR8
// })

export const setControllerSettings = (settings) => ({
    type: SET_CONTROLLER_SETTINGS,
    settings
})

export const setBattery = (battery) => ({
    type: SET_BATTERY,
    battery
})

export const setGraphicsDashboard = (graphicsDashboard) => ({
    type: SET_GRAPHICS_ARRAY,
    graphicsDashboard
})


export const setErrorDevices = (errorDevice) => ({
    type: SET_ERROR_DEVICES,
    errorDevice
})


export const setAddDevices = (addDevicesConst) => ({
    type: SET_ADD_DEVICES,
    addDevicesConst
})


export const setErrorR8Devices = (errorR8Device) => ({
    type: SET_ERROR_R8_DEVICES,
    errorR8Device
})


export const setAddR8Devices = (addDevicesR8Const) => ({
    type: SET_ADD_R8_DEVICES,
    addDevicesR8Const
})

export const setLinkAc = (link) => ({
    type: SET_LINK,
    link
})

export const setSensors = (sensors) => ({
    type: SET_SENSORS,
    sensors
})

export const setGraphics = (graphics) => ({
    type: SET_METER_GRAPHIC,
    graphics
})

export const setEvents = (events) => ({
    type: SET_EVENT_GRAPHIC,
    events
})

export const setEventsArrayThunk = (eventsArray) => ({
    type: SET_EVENTS_ARRAY,
    eventsArray
})

export const setMeters = (meters) => ({
    type: SET_METERS,
    meters
})

export const setFetch = (fetch) => ({
    type: SET_FETCH,
    fetch
})

export const setReleaseNotes = (releaseNotes) => ({
    type: SET_RELEASE_NOTES,
    releaseNotes
})

//****THUNK****//

export const saveDisableByBalance = (disableByBalance) => {
    return (
        (dispatch) => {
            dispatch(setDisableByBalanceA(disableByBalance))
        }
    )
}

export const saveEventsSettings = (eventsSettings) => {
    return (
        (dispatch) => {
            dispatch(setEventsSettings(eventsSettings))
        }
    )
}

export const saveGraphicSettings = (graphicsSettings) => {
    return (
        (dispatch) => {
            dispatch(setGraphicSettings(graphicsSettings))
        }
    )
}

export const saveMeterSettings = (meterSettings) => {
    return (
        (dispatch) => {
            dispatch(setMeterSettings(meterSettings))
        }
    )
}

export const saveReadingSettings = (readingSettings) => {
    return (
        (dispatch) => {
            dispatch(setReadingSettings(readingSettings))
        }
    )
}

export const getLink = (link) => {
    return (
        (dispatch) => {
            dispatch(setLinkAc(link))
        }
    )
}

export const getMeterData = (sid, id, date) => {
    mDate = date
    return (
        (dispatch) => {
            // Promise.all([
            //     dispatch(getJournal(AuthHelper.getSid(), 1, ObjectsHelper.getObjectId(), AppConstants.JOURNAL_STEP))
            // ]).then(() => {
            //     dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), mDate))
            // })

            dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), mDate))

            // мне нужно было, чтобы журнал загружался вместе с датчиками. Я создал этот метод. Но потом мне это стало не нужно, и, чтобы не сломать ничего, я просто вызову из этого метода другой метод. Нужно поправить
        }
    )
}


export const getMeterGraphic = (sid, id, dateStart, dateEnd, groupBy, absolute) => {
    let mDateStart = dateStart
    let mDateEnd = dateEnd
    let mGroupBy = groupBy
    let mAbsolute = absolute
    // debugger
    return (
        (dispatch) => {

            ObjectsApi.getMetersGraphic(sid, id, mDateStart, mDateEnd, mGroupBy, mAbsolute)
                .then(data => {
                    // debugger
                    if (data.status === 'ok') {
                        dispatch(setGraphics(data.data))
                    } else {
                        if (data.errors[0].name !== undefined) {
                            if (data.errors[0].name === "WrongSIDException") {
                                Promise.all([
                                    dispatch(getSid())
                                ]).then(() => {
                                    dispatch(getMeterGraphic(AuthHelper.getSid(), MetersHeler.getMeterId(), mDateStart, mDateEnd, mGroupBy, mAbsolute))
                                })
                            }
                        }

                    }
                })
        }
    )
}

export const getMeters = (sid, id, date) => {

    mDate = date.replace("T11:59:59", "T23:59:59")
    let eventsMeterIdArray = []
    let graphicsMeterIdArray = []
    let metersMeterIdArray = []
    let readingsMeterIdArray = []

    localStorage.removeItem("EIRC_ARRAY")

    return (
        (dispatch) => {

            if (ObjectsHelper.getObjects() && ObjectsHelper.getObjects().length !== 0) {

                let a = ObjectsHelper.getObjects()
                let b = ObjectsHelper.getObjectId()

                if (!a.some(item => b === String(item.id))) {
                    ObjectsHelper.setObjectId(ObjectsHelper.getObjects()[0].id)
                    ObjectsHelper.setObjectAdress(ObjectsHelper.getObjects()[0].house + ' ' + ObjectsHelper.getObjects()[0].number)
                }
            }

            dispatch(setFetch(true))

            ObjectsApi.getMeters(sid, ObjectsHelper.getObjectId(), mDate)
                .then(data => {
                    dispatch(setFetch(false))
                    if (data.status === 'ok') {

                        let metersArray = []
                        let arrayOfEircNums = []

                        try {
                            data.data.sensors.map(sensor => {
                                sensor.meters.map(meter => {
                                    arrayOfEircNums.push(meter.eirc_num)

                                    metersArray.push({
                                        ...meter,
                                        controller_name: sensor.name,
                                        controller_sn: sensor.sn,
                                        controller_readout_dt: sensor.readout_dt,
                                    })

                                    const {type, meter_id, meter_name, sn} = meter
                                    if ([4, 6, 9, 10, 1001].some(s => s === type.number)) {
                                        eventsMeterIdArray.push(
                                            {
                                                "meter_sn": sn,
                                                "meter_id": meter_id,
                                                "meter_type": type.number,
                                                "meter_name": meter_name !== "" ? meter_name : meterTypeName(type.number),
                                                "visible": true
                                            }
                                        )
                                    }
                                    if ([5, 14, 15, 1000].some(s => s === type.number)) {
                                        graphicsMeterIdArray.push(
                                            {
                                                "meter_sn": sn,
                                                "meter_id": meter_id,
                                                "meter_type": type.number,
                                                "meter_name": meter_name !== "" ? meter_name : meterTypeName(type.number),
                                                "visible": true
                                            }
                                        )
                                    }
                                    if ([1, 2, 3, 7, 8, 11, 12, 13, 1003].some(s => s === type.number)) {
                                        metersMeterIdArray.push(
                                            {
                                                "meter_sn": sn,
                                                "meter_id": meter_id,
                                                "meter_type": type.number,
                                                "meter_name": meter_name !== "" ? meter_name : meterTypeName(type.number),
                                                "visible": true
                                            }
                                        )

                                        readingsMeterIdArray.push(
                                            {
                                                "meter_sn": sn,
                                                "meter_id": meter_id,
                                                "meter_type": type.number,
                                                "meter_name": meter_name !== "" ? meter_name : meterTypeName(type.number),
                                                "visible": true
                                            }
                                        )
                                    }
                                })
                            })
                        } catch (e) {

                        }

                        //показания

                        if (readingsMeterIdArray.length > 4) {
                            readingsMeterIdArray.map((item, i) => {
                                item.visible = false
                            })
                        }
                        let tempReadingsArray = []
                        readingsMeterIdArray.map((item, i) => {
                            if (item.meter_type === 1 || item.meter_type === 2) tempReadingsArray.push(item.meter_type)
                        })
                        if (tempReadingsArray.length !== 0) {
                            readingsMeterIdArray.some((item, i) => {
                                if (!tempReadingsArray.includes(item.meter_type)) {
                                    readingsMeterIdArray.map((item, i) => {
                                        item.visible = false
                                    })
                                }
                            })
                        }

                        //измерения
                        if (graphicsMeterIdArray.length > 4) {
                            graphicsMeterIdArray.map((item, i) => {
                                item.visible = false
                            })
                        }
                        graphicsMeterIdArray.some((item, i) => {
                            if (item.meter_type !== graphicsMeterIdArray[0].meter_type) {
                                graphicsMeterIdArray.map((item, i) => {
                                    item.visible = false
                                })
                            }
                        })

                        // расход

                        if (metersMeterIdArray.length > 4) {
                            metersMeterIdArray.map((item, i) => {
                                item.visible = false
                            })
                        }
                        let tempMeterArray = []
                        metersMeterIdArray.map((item, i) => {
                            if (item.meter_type === 1 || item.meter_type === 2) tempMeterArray.push(item.meter_type)
                        })
                        if (tempMeterArray.length !== 0) {
                            metersMeterIdArray.some((item, i) => {
                                if (!tempMeterArray.includes(item.meter_type)) {
                                    metersMeterIdArray.map((item, i) => {
                                        item.visible = false
                                    })
                                }
                            })
                        }

                        MetersHeler.setEventsId(eventsMeterIdArray)
                        MetersHeler.setGraphicsId(graphicsMeterIdArray)
                        MetersHeler.setMetersId(metersMeterIdArray)
                        MetersHeler.setReadingsId(readingsMeterIdArray)
                        MetersHeler.setAllSensors(data.data.sensors)
                        MetersHeler.setEircArray(arrayOfEircNums)

                        setMenuHide()

                        MetersHeler.getAllSensors().map((item, i) => {
                            if (MetersHeler.getControllerItem()) {
                                if (item.sn === MetersHeler.getControllerItem().sn) {
                                    localStorage.removeItem(AppConstants.CONTROLLER)
                                    MetersHeler.setControllerItem(item)
                                }
                            }
                        })

                        Promise.all([
                            dispatch(setMetersThunk(metersArray))
                        ]).then(() => {
                            dispatch(setSensors(data.data.sensors))
                        })


                    } else {
                        if (data.errors[0].name !== undefined) {
                            if (data.errors[0].name === "WrongSIDException") {
                                Promise.all([dispatch(getSid())]).then(() => {
                                    dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), mDate))
                                })
                            }

                            if (data.errors[0].name === "AbstractException") {
                                errorToast(data.errors[0].msg)
                            }

                            if (data.errors[0].name === "PermissionDenied") {
                                errorToast(data.errors[0].msg)
                            }
                        }
                    }

                    setMenuHide()
                })
        }
    )
}

export const getOnlyEventsGraphic = (idsArray, startDate, endDate, group = "day", absolute = false) => {

    let mStartDate = startDate
    let mendDate = endDate
    let idsArray1 = idsArray
    let promises = [];
    let eventArray = []
    let graphicsArray = []
    let metersArray = []
    let absoluteParam = absolute ? "&absolute=1" : ""
    localStorage.removeItem(AppConstants.METERS_ARRAY)
    localStorage.removeItem(AppConstants.EVENTS_ARRAY)
    localStorage.removeItem(AppConstants.GRAPHICS_ARRAY)

    return (
        (dispatch) => {

            if (!AuthHelper.getSid()) {
                dispatch(setLogout())
                return
            }

            for (let i = 0; i < idsArray1.length; i++) {
                setTimeout(() => {
                    if (idsArray1[i].visible) {

                        promises.push(
                            axios.get(AppConstants.GRAPHIC_METER_PATH + `?sid=${AuthHelper.getSid()}&id=${idsArray1[i].meter_id}&start=${startDate}&finish=${endDate}&group=${group}${absoluteParam}`)
                                .then(response => {
                                    let data = response.data
                                    if (data.status === 'ok') {
                                        let filterEvents = (array, item) => {
                                            if (array.some(element => JSON.stringify(element) === JSON.stringify(item))) return true
                                            else return false
                                        }
                                        try {
                                            if ([4, 6, 9, 10, 1001].some(s => s === data.data.type)) {
                                                if (!filterEvents(idsArray1, data.data)) {
                                                    data.data.points.map(point => {
                                                        eventArray.push(
                                                            {
                                                                date: moment(point.datetime, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY HH:mm:ss'),
                                                                dateMs: moment(point.datetime, 'YYYY-MM-DDTHH:mm:ss').valueOf(),
                                                                value: point.vals[0],
                                                                state: point.state,
                                                                name: data.data.name,
                                                                type: data.data.type,
                                                                activeText: data.data.active_text,
                                                                passiveText: data.data.passive_text
                                                            }
                                                        )
                                                    })
                                                    let sortEvent = (a, b) => {
                                                        return b.dateMs - a.dateMs;
                                                    }
                                                    eventArray.sort(sortEvent)
                                                }
                                            }

                                            if ([5, 14, 15, 1000].some(s => s === data.data.type)) {
                                                graphicsArray.push(
                                                    {...data.data, meter_id: idsArray1[i].meter_id}
                                                )
                                            }

                                            if ([1, 2, 3, 7, 8, 11, 12, 13, 1003].some(s => s === data.data.type)) {
                                                metersArray.push(
                                                    {...data.data, meter_id: idsArray1[i].meter_id}
                                                )
                                            }
                                        } catch (e) {
                                            console.log(e)
                                        }
                                    } else {
                                        if (data.errors[0].name !== undefined) {
                                            if (data.errors[0].name === "WrongSIDException") {
                                                Promise.all([
                                                    dispatch(getSid())
                                                ]).then(() => {
                                                    dispatch(getOnlyEventsGraphic(idsArray1, mStartDate, mendDate, group))
                                                })
                                            }
                                        }
                                    }
                                })
                        )
                    }

                    Promise.all(promises)
                        .then(() => {
                            MetersHeler.setEventsArray(eventArray)
                            MetersHeler.setMetersArray(metersArray)
                            dispatch(setGraphicsDashboard(graphicsArray))
                            dispatch(setEventsArrayThunk(graphicsArray))
                        })

                }, 200 * (i + 1))

            }

            // setTimeout(() => {
            //
            // }, 200 * (i + 1))

        }
    )
}

export const getControllerSettings = (sid, sn) => (dispatch) => {
    let _sn = sn
    return objectsApi.getControllerSettings(AuthHelper.getSid(), _sn)
        .then(data => {
                dispatch(setAddDevices(false))
                dispatch(setErrorDevices(null))
                if (data.status === 'ok') {
                    dispatch(setControllerSettings(data.data))
                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        let promiseGetSid = dispatch(getSid())
                        Promise.all([promiseGetSid]).then(() => {
                            dispatch(getControllerSettings(AuthHelper.getSid(), _sn))
                        })
                    }
                }
            }
        )
}

export const getEventsList = (startDate, endDate) => {
    getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), endDate)
    return (
        (dispatch) => {
            let promise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    dispatch(getOnlyEventsGraphic(MetersHeler.getEventsId(), startDate, endDate))
                }, 700);
            });
        }
    )
}

export const getGraphicList = (startDate, endDate) => {
    getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), endDate)
    return (
        (dispatch) => {
            let promise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    dispatch(getOnlyEventsGraphic(MetersHeler.getGraphicsId(), startDate, endDate))
                }, 700);
            });
        }
    )
}

export const getMetersList = (startDate, endDate) => {
    getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), endDate)
    return (
        (dispatch) => {
            let promise = new Promise((resolve, reject) => {
                setTimeout(() => {
                    dispatch(getOnlyEventsGraphic(MetersHeler.getMetersId(), startDate, endDate))
                }, 700);
            });
        }
    )
}

export const deleteMeters = (sid, id, date) => (dispatch) => {
    let mId = id
    mDate = date
    return objectsApi.deleteMeters(AuthHelper.getSid(), mId)
        .then(data => {
                if (data.status === 'ok') {
                    let promiseGetMeters = dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), mDate))
                    Promise.all([promiseGetMeters]).then(() => {
                        successToast("Устройство успешно удалено")
                    })
                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        let promiseGetSid = dispatch(getSid())
                        Promise.all([promiseGetSid]).then(() => {
                            dispatch(deleteMeters(AuthHelper.getSid(), mId))
                        })
                    }

                    if (data.errors[0].name === "PermissionDenied") {
                        errorToast(data.errors[0].msg)
                    }

                    if (data.errors[0].name === "AbstractException") {
                        errorToast(data.errors[0].msg)
                    }
                }
            }
        )
}

export const getDevices = (sid, sn) => (dispatch) => {
    let mSn = sn
    return objectsApi.getDevice(AuthHelper.getSid(), mSn)
        .then(data => {
                dispatch(setAddDevices(false))
                dispatch(setErrorDevices(null))
                if (data.status === 'ok') {
                    dispatch(setGetDevices(data.data))
                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        let promiseGetSid = dispatch(getSid())
                        Promise.all([promiseGetSid]).then(() => {
                            dispatch(getDevices(AuthHelper.getSid(), mSn))
                        })
                    }

                    if (data.errors[0].sn) {
                        // errorToast(data.errors[0].sn[0])
                        dispatch(setErrorDevices(data.errors[0].sn[0]))
                    }

                    else if (data.errors[0].name === "SystemError") {
                        errorToast("Неизвестная ошибка")
                    }
                }
            }
        )
}

export const addDevices = (params) => (dispatch) => {
    let param = params
    let getDevSn = params.get("sn")
    return objectsApi.addDeviceToController(param)
        .then(data => {
                dispatch(setAddDevices(false))
                if (data.status === 'ok') {
                    Promise.all([
                        // dispatch(setAddDevices(true))
                        successToast("Устройство успешно добавлено в кабинет")
                    ]).then(() => {
                        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment(setMinutes()).format("YYYY-MM-DDTHH:mm:ss")))
                    }).then(() => {
                        dispatch(getObjects(AuthHelper.getSid()))
                    }).then(() => {
                        dispatch(getDevices(AuthHelper.getSid(), getDevSn))
                    })
                } else {
                    Object.entries(data.errors).map(([key, value]) => {
                        let a
                        let name
                        for (let i = 0; i < 99; i++) {
                            a = value.hasOwnProperty(i + "_name")
                            if (a === true) {
                                name = i + "_name"
                                console.log(data.errors[0][name][0])
                                errorToast(data.errors[0][name][0])
                            }
                        }
                    })

                    dispatch(setAddDevices(false))

                    if (data.errors[0].name === "WrongSIDException") {
                        params.delete('sid')
                        let promiseGetSid = dispatch(getSid())
                        Promise.all([promiseGetSid]).then(() => {
                            param.append('sid', AuthHelper.getSid());
                            dispatch(updateController(param))
                        })
                    }

                    if (data.errors[0].sn && data.errors[0].sn !== null) {
                        errorToast("Неверный серийный номер")
                    }

                    if (data.errors[0].add_lic && data.errors[0].add_lic !== null) {
                        errorToast(data.errors[0].add_lic[0])
                    }

                    if (data.errors[0].name === "PermissionDenied") {
                        errorToast(data.errors[0].msg)
                    }

                    if (data.errors[0].name === "AbstractException") {
                        errorToast(data.errors[0].msg)
                    }

                    if (data.errors[0].name === "SystemError") {
                        errorToast("Неизвестная ошибка")
                    }

                    if (data.errors[0].object_id && data.errors[0].object_id !== null) {
                        errorToast("Неверный идентификатор объекта")
                    }


                }
            }
        )
}


export const updateController = (params) => (dispatch) => {
    let param = params
    let firmware = param.get("new_firmware")
    let updateScene = firmware !== "" ? "Успешно. Контроллер обновится при следующем выходе на связь" : "Обновление контроллера отменено"
    return objectsApi.updateController(param)
        .then(data => {
                if (data.status === 'ok') {
                    Promise.all([
                        successToast(updateScene)
                    ]).then(() => {
                        dispatch(getControllerSettings(
                            AuthHelper.getSid(),
                            param.get("sn")
                        ))
                    }).then(() => {
                        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment(setMinutes()).format("YYYY-MM-DDTHH:mm:ss")))
                    })
                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        params.delete('sid')
                        let promiseGetSid = dispatch(getSid())
                        Promise.all([promiseGetSid]).then(() => {
                            param.append('sid', AuthHelper.getSid());
                            dispatch(updateController(param))
                        })
                    }

                    if (data.errors[0].sn) {
                        errorToast(data.errors[0].sn[0])
                        //dispatch(setErrorDevices(data.errors[0].sn[0]))
                    }

                    if (data.errors[0].new_firmware) {
                        errorToast(data.errors[0].new_firmware[0])
                    }

                    if (data.errors[0].name === "PermissionDenied") {
                        errorToast(data.errors[0].msg)
                    }

                    if (data.errors[0].name === "AbstractException") {
                        errorToast(data.errors[0].msg)
                    }
                }
            }
        )
}

export const getControllerBattery = (sid, sn, start, finish) => (dispatch) => {
    let mSid = sid
    let mSn = sn
    let mStart = start
    let mFinish = finish
    return objectsApi.getBattery(AuthHelper.getSid(), mSn, mStart, mFinish)
        .then(data => {
                dispatch(setAddDevices(false))
                dispatch(setErrorDevices(null))
                if (data.status === 'ok') {
                    dispatch(setBattery(data.data))
                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        let promiseGetSid = dispatch(getSid())
                        Promise.all([promiseGetSid]).then(() => {
                            dispatch(getControllerBattery(AuthHelper.getSid(), mSn, mStart, mFinish))
                        })
                    }

                    if (data.errors[0].sn) {
                        // errorToast(data.errors[0].sn[0])
                        dispatch(setErrorDevices(data.errors[0].sn[0]))
                    }
                }
            }
        )
}

export const updateMeter = (params, eircMaster = false) => (dispatch) => {
    let param = params

    let toastSuccess = eircMaster === false ? "Настройки сохранены" : "Перейдите в меню Отправка и сделайте тестовую отправку. Проконтролируйте результат в меню Журнал."

    return objectsApi.updateMeter(param)
        .then(data => {
                // debugger
                if (data.status === 'ok') {
                    Promise.all([
                        successToast(toastSuccess),
                        dispatch(setEditIsLoaded(false))
                    ]).then(() => {
                        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment(setMinutes()).format("YYYY-MM-DDTHH:mm:ss")))
                    })
                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        params.delete('sid')
                        let promiseGetSid = dispatch(getSid())
                        Promise.all([promiseGetSid]).then(() => {
                            param.append('sid', AuthHelper.getSid());
                            dispatch(updateController(param))
                        })
                    }

                    if (data.errors[0].sn) {
                        errorToast(data.errors[0].sn[0])
                        //dispatch(setErrorDevices(data.errors[0].sn[0]))
                    }

                    if (data.errors[0].active_text && data.errors[0].passive_text) {
                        errorToast("Невозможно применить настройки. Неизвестное устройство")
                        //dispatch(setErrorDevices(data.errors[0].sn[0]))
                    }

                    if (data.errors[0].approve_dt) {
                        errorToast("Неправильная дата поверки")
                    }

                    if (data.errors[0].name === "PermissionDenied") {
                        errorToast(data.errors[0].msg)
                    }

                    if (data.errors[0].name === "AbstractException") {
                        errorToast(data.errors[0].msg)
                    }
                }
            }
        )
}

export const updateMeterEirc = (arrayOfParams) => (dispatch) => {
    let promises = []
    for (let i = 0; i < arrayOfParams.length; i++) {
        let param = arrayOfParams[i]

        promises.push(
            axios
                .post(AppConstants.UPDATE_METER_PATH, param)
                .then(response => {
                        let data = response.data
                        if (data.status === 'ok') {

                        } else {
                            if (data.errors[0].name === "WrongSIDException") {
                                param.delete('sid')
                                let promiseGetSid = dispatch(getSid())
                                Promise.all([promiseGetSid]).then(() => {
                                    param.append('sid', AuthHelper.getSid());
                                    dispatch(updateMeter(param, true))
                                })
                            }

                            if (data.errors[0].sn) {
                                errorToast(data.errors[0].sn[0])
                                //dispatch(setErrorDevices(data.errors[0].sn[0]))
                            }

                            if (data.errors[0].active_text && data.errors[0].passive_text) {
                                errorToast("Невозможно применить настройки. Неизвестное устройство")
                                //dispatch(setErrorDevices(data.errors[0].sn[0]))
                            }

                            if (data.errors[0].approve_dt) {
                                errorToast("Неправильная дата поверки")
                            }

                            if (data.errors[0].name === "PermissionDenied") {
                                errorToast(data.errors[0].msg)
                            }

                            if (data.errors[0].name === "AbstractException") {
                                errorToast(data.errors[0].msg)
                            }
                        }
                    }
                )
        )

    }
    // setTimeout(() => {
    //
    // }, 1000)
    Promise.all(promises)
        .then(() => {
            successToast("Перейдите в меню Отправка и сделайте тестовую отправку. Проконтролируйте результат в меню Журнал.")
        }).then(() => {
        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment(setMinutes()).format("YYYY-MM-DDTHH:mm:ss")))
    })
}

export const settingsController = (params) => (dispatch) => {
    let param = params
    return objectsApi.updateController(param)
        .then(data => {
                if (data.status === 'ok') {
                    Promise.all([
                        successToast("Настройки успешно сохранены")
                    ]).then(() => {
                        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment(setMinutes()).format("YYYY-MM-DDTHH:mm:ss")))
                    })
                } else {


                    if (data.errors[0].name === "WrongSIDException") {
                        params.delete('sid')
                        let promiseGetSid = dispatch(getSid())
                        Promise.all([promiseGetSid]).then(() => {
                            param.append('sid', AuthHelper.getSid());
                            dispatch(settingsController(param))
                        })
                    } else {
                        errorToast(data.errors[0].msg)
                    }


                    if (data.errors[0].log !== undefined) {
                        errorToast(data.errors[0].log[0])
                    }

                    if (data.errors[0].vol !== undefined) {
                        errorToast(data.errors[0].vol[0])
                    }

                    if (data.errors[0].scan !== undefined) {
                        errorToast(data.errors[0].scan[0])
                    }

                    if (data.errors[0].send !== undefined) {
                        errorToast(data.errors[0].send[0])
                    }

                    if (data.errors[0].check_hours !== undefined) {
                        errorToast(data.errors[0].check_hours[0])
                    }
                }
            }
        )
}

export const getReleaseNotes = (hw) => (dispatch) => {

    let mHw = hw
    return objectsApi.getReleaseNotes(mHw)
        .then(data => {
                if (data.status === 'ok') {
                    dispatch(setReleaseNotes(data.data))
                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        Promise.all([
                            dispatch(getSid())
                        ]).then(() => {
                            dispatch(getReleaseNotes(mHw))
                        })
                    }

                    if (data.errors[0].name === "PermissionDenied") {
                        errorToast(data.errors[0].msg)
                    }

                    if (data.errors[0].name === "AbstractException") {
                        errorToast(data.errors[0].msg)
                    }
                }
            }
        )
}

export const setMetersThunk = (meters) => {
    return (
        (dispatch) => {
            dispatch(setMeters(meters))
        }
    )
}

let switchCommndStringToId = (commandText) => {
    switch (commandText) {
        case "open":
            return 0
        case "close":
            return 1
        default:
            return -1
    }
}

export const getMeterControl = (params) => {
    let param = params
    let controlCommandText = switchCommndStringToId(param.get("command"))
    let controlId = param.get("id")
    return (
        (dispatch) => {
            ObjectsApi.getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment(setMinutes()).format("YYYY-MM-DDTHH:mm:ss"))
                .then(data => {
                    if (data.status === 'ok') {
                        let sensors = data.data.sensors
                        sensors.map((item, i) => {
                            item.meters.map((item_child, i_child) => {
                                if (controlId === String(item_child.meter_id)) {
                                    if (item_child.vals[0] === undefined || controlCommandText !== item_child.vals[0]) {
                                        dispatch(controlCommand(param))
                                    } else {
                                        errorToast("Состояние устройства изменилось, попробуйте ещё раз")
                                        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment(setMinutes()).format("YYYY-MM-DDTHH:mm:ss")))
                                    }
                                }
                            })
                        })
                    } else {
                        if (data.errors[0].name !== undefined) {
                            if (data.errors[0].name === "WrongSIDException") {
                                Promise.all([dispatch(getSid())]).then(() => {
                                    dispatch(getMeterControl(param))
                                })
                            }
                        }
                    }
                })
        }
    )
}


export const controlCommand = (params) => (dispatch) => {
    let param = params
    let command = param.get("command")
    return objectsApi.controlCommand(param)
        .then(data => {
                if (data.status === 'ok') {
                    Promise.all([
                        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment(setMinutes()).format("YYYY-MM-DDTHH:mm:ss")))
                    ]).then(() => {
                        if (command !== "clear") successToast("Команда сработает при следующем выходе контроллера на связь")
                    })
                } else {
                    if (data.errors[0].name === "WrongSIDException") {
                        params.delete('sid')
                        Promise.all([
                            dispatch(getSid())
                        ]).then(() => {
                            param.append('sid', AuthHelper.getSid());
                            dispatch(controlCommand(param))
                        })
                    }

                    if (data.errors[0].name === "PermissionDenied") {
                        errorToast(data.errors[0].msg)
                    }

                    if (data.errors[0].name === "AbstractException") {
                        errorToast(data.errors[0].msg)
                    }
                }
            }
        )
}

export const controllerMove = (params) => (dispatch) => {
    let param = params
    return objectsApi.controllerMoveApi(param)
        .then(data => {
                debugger
                if (data.status === 'ok') {
                    Promise.all([
                        successToast("Успешно")
                    ]).then(() => {
                        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment(setMinutes()).format("YYYY-MM-DDTHH:mm:ss")))
                    })
                } else {

                    if (data.errors[0].name === "WrongSIDException") {
                        params.delete('sid')
                        Promise.all([
                            dispatch(getSid())
                        ]).then(() => {
                            param.append('sid', AuthHelper.getSid());
                            dispatch(controllerMove(param))
                        })
                    }

                    if (data.errors[0].name === "PermissionDenied") {
                        errorToast(data.errors[0].msg)
                    }

                    if (data.errors[0].name === "AbstractException") {
                        errorToast(data.errors[0].msg)
                    }
                }
            }
        )
}

export const controllerClear = (sid, sn) => (dispatch) => {
    let mSn = sn
    return objectsApi.controllerClearApi(sid, mSn)
        .then(data => {
                if (data.status === 'ok') {
                    Promise.all([
                        successToast("Успешно")
                    ]).then(() => {
                        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment(setMinutes()).format("YYYY-MM-DDTHH:mm:ss")))
                    })
                } else {

                    if (data.errors[0].name === "WrongSIDException") {
                        Promise.all([
                            dispatch(getSid())
                        ]).then(() => {
                            dispatch(controllerClear(AuthHelper.getSid(), mSn))
                        })
                    }

                    if (data.errors[0].name === "PermissionDenied") {
                        errorToast(data.errors[0].msg)
                    }

                    if (data.errors[0].name === "AbstractException") {
                        errorToast(data.errors[0].msg)
                    }
                }
            }
        )
}

export const controllerDelete = (sid, sn) => (dispatch) => {
    let mSn = sn
    return objectsApi.controllerDeleteApi(sid, mSn)
        .then(data => {
                debugger
                if (data.status === 'ok') {
                    Promise.all([
                        successToast("Успешно")
                    ]).then(() => {
                        dispatch(getMeters(AuthHelper.getSid(), ObjectsHelper.getObjectId(), moment(setMinutes()).format("YYYY-MM-DDTHH:mm:ss")))
                    })
                } else {

                    if (data.errors[0].name === "WrongSIDException") {
                        Promise.all([
                            dispatch(getSid())
                        ]).then(() => {
                            dispatch(controllerDelete(AuthHelper.getSid(), mSn))
                        })
                    }

                    if (data.errors[0].name === "PermissionDenied") {
                        errorToast(data.errors[0].msg)
                    }

                    if (data.errors[0].name === "AbstractException") {
                        errorToast(data.errors[0].msg)
                    }
                }
            }
        )
}


export default MeterReducer

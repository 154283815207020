import {AuthHelper} from "../helpers/AuthHelper";
import * as AppConstants from "./constants/AppConstants";
import {errorToast} from "./Toasts";
import {ObjectsHelper} from "../helpers/ObjectsHelper";
import {MetersHeler} from "../helpers/MetersHelper";
import {meterTypeName} from "../components/dashboard/utils/DashboardUtils";
import React from "react";
import moment from "moment";
import {APP_VERSION, SHOW_ALERT_JOURNAL} from "./constants/AppConstants";

export let isDemo = () => {
    return AuthHelper.getCurrentUser() === AppConstants.DEMO_LOGIN;
}

export let returnToHomePage = () => {
    window.location.href = AppConstants.DASHBOARD_PAGE
}

export let rootUrl = () => {
    let host = window.location.host

    switch (host) {
        case "lk.saures.ru" :
            return "https://api.saures.ru/1.0"
        case "test.saures.ru" :
            return "https://testapi.saures.ru/1.0"
        default:
            return "https://testapi.saures.ru/1.0"
    }
}

export let getHost = () => {
    return window.location.host
}

export let returnAppUrl = () => {
    if (localStorage.getItem("API_URL") === "https://api.saures.ru") {
        return "https://lk.saures.ru"
    } else {
        return "https://test.saures.ru"
    }
}

export let checkZeroObjects = () => {
    if (ObjectsHelper.getObjects() && ObjectsHelper.getObjects().length === 0) {
        errorToast("У вас нет ни одного объекта. Предварительно необходимо создать объект")
        return true
    } else return false
}

export let setMenuHide = () => {

    if (document.getElementById('meterPage') && document.getElementById('graphicPage') && document.getElementById('eventPage')) {

        if (!MetersHeler.getMetersId()) document.getElementById('meterPage').style.display = "none"
        if (!MetersHeler.getMetersId()) document.getElementById('readingPage').style.display = "none"
        if (!MetersHeler.getGraphicsId()) document.getElementById('graphicPage').style.display = "none"
        if (!MetersHeler.getEventsId()) document.getElementById('meterPage').style.display = "none"

        document.getElementById('meterPage').style.display =   MetersHeler.getMetersId()   ?   MetersHeler.getMetersId().length === 0 ? "none" : "block" : "none"
        document.getElementById('readingPage').style.display = MetersHeler.getMetersId()   ?   MetersHeler.getMetersId().length === 0 ? "none" : "block" : "none"
        document.getElementById('graphicPage').style.display = MetersHeler.getGraphicsId() ?   MetersHeler.getGraphicsId().length === 0 ? "none" : "block" : "none"
        document.getElementById('eventPage').style.display =   MetersHeler.getEventsId()   ?   MetersHeler.getEventsId().length === 0 ? "none" : "block" : "none"

    }

    if (document.getElementById('paymentPage') && ObjectsHelper.getObjectItem()) {
        // document.getElementById('paymentPageTitle').innerHTML = "Баланс: " + getObjectBalance().balance + "р."  //Тарифы
        // document.getElementById('paymentPage').style.display = ObjectsHelper.getObjectItem().tariffs ? ObjectsHelper.getObjectItem().tariffs.length === 0 ? "none" : "block" : "none"

        if (ObjectsHelper.getObjectItem().tariffs && ObjectsHelper.getObjectItem().tariffs.length !== 0) {
            document.getElementById('paymentPageTitle').innerHTML = "Услуги: " + getObjectBalance().balance + " руб."
        } else {
            document.getElementById('paymentPageTitle').innerHTML = "Услуги"
        }
    }

}

export let systemAlerts = () => {
    if (!localStorage.getItem(APP_VERSION)) localStorage.setItem(APP_VERSION, AppConstants.APP_VER)

    if (!localStorage.getItem(SHOW_ALERT_JOURNAL)) localStorage.setItem(SHOW_ALERT_JOURNAL, true)

}

export let objectEnable = () => {
    let objectItem = ObjectsHelper.getObjectItem()
    let enable = objectItem !== null ? objectItem.enable : true
    if (enable)
    return enable
}

export let setMinutes = () => {
    return(
        {
            hours: 23,
            minutes: 59,
            seconds: 59
        }
    )
}

export const tariffBlock = (tariffName, tariffDescription) => {
    return (
        <span>
            <span className={"font-medium"}>{tariffName}</span>
            <br/>
            <span className={"pre_wrap"}>{htmlRegex(tariffDescription)}</span>
        </span>
    )
}

export let getObjectBalance = () => {
    let tariffArray
    try {
        if (ObjectsHelper.getObjectItem()) {
            tariffArray = ObjectsHelper.getObjectItem().tariffs
        }
    } catch (e) {
        tariffArray = []
    }

    let balance = 0.0
    let sumBalance = 0
    let tariffName = ""
    let tariffSum = []

    if (tariffArray !== undefined) {
        tariffArray.map((item, i) => {
            balance = parseFloat(item !== undefined ? item !== null ? item.balance.replace("р.", "").replace(",", ".") : "0.0" : "0.0")
            tariffSum.push(balance)
        })
    }

    sumBalance = tariffSum.reduce((a, b) => a = a + b, 0 ).toFixed(0) // суммирую все балансы

    return {tariffName: tariffName, balance: sumBalance}
}

export let htmlRegex = (content) => {
    if (content !== undefined) return content.replace(/(<([^>]+)>)/ig, "")
    else return ""
}

export let moreThanDays = (a, b, groupBy) => {

    //start = "2021-12-06T00%3A00%3A00"

    // let start = moment(a.valueOf()).format('DD.MM.YYYY') === "Invalid date" ? a : moment(a.valueOf()).format('DD.MM.YYYY')
    let start = moment(a, 'YYYY-MM-DDTHH:mm:ss').format('DD.MM.YYYY')
    let finish = typeof b === "string" ? b : moment(b.valueOf()).format('DD.MM.YYYY')
    let millisecondsStart = moment(start, 'DD.MM.YYYY').valueOf()
    let millisecondsEnd = moment(finish, 'DD.MM.YYYY').valueOf()
    let diffBetweenDates = millisecondsEnd - millisecondsStart //1382400000 - 01 // 26784000000 - 1
    let month31 = 2678400000
    let year366 = 31557600000

    switch (groupBy) {
        case "day": {
            return diffBetweenDates > year366
        }
        case "hour": {
            return diffBetweenDates > month31
        }
        default: return false
    }
}

export let dataToPVEditChannelInputForm = {
    pv: [
        {value: 0, label: 'Установить абсолютное значение'},
        {value: 1, label: 'Увеличить на значение'},
        {value: -1, label: 'Уменьшить на значение'},
    ]
}

export let dataToAddNewR8InputForm = {
    meterType: [
        {value: '0', label: 'Отключен'},
        {value: '1', label: 'Счетчик ХВС, л'},
        {value: '2', label: 'Счетчик ГВС, л'},
        {value: '3', label: 'Счетчик Газа, л'},
        {value: '5', label: 'Датчик температуры, град'},
        {value: '7', label: 'Счетчик Тепла, Вт*ч'},
        {value: '13', label: 'Счетчик Тепла, ккал'},
        {value: '8', label: 'Счетчик Электричества, Вт*ч'},
        {value: '9', label: 'Датчик контактный'},
        {value: '14', label: 'Датчик давления, бар'}
    ],
    meterInput: [
        {value: '1', label: '1'},
        {value: '2', label: '2'},
        {value: '3', label: '3'},
        {value: '4', label: '4'},
        {value: '5', label: '5'},
        {value: '6', label: '6'},
        {value: '7', label: '7'},
        {value: '8', label: '8'}
    ],
    signalType: [
        {value: '0', label: 'Отключен'},
        {value: '1', label: 'Авто Намур/Геркон'},
        {value: '2', label: 'Геркон'},
        {value: '3', label: 'Открытый коллектор'},
        {value: '4', label: 'Открытый коллектор 1/64 сек'},
        {value: '5', label: 'Тип 1 (NTC, 10 КОм, В=3300)'},
        {value: '6', label: 'Тип 2 (NTC, 10 КОм, В=3988)'},
        {value: '7', label: 'Тип 3 (NTC, 10 КОм, В=3435)'},
        {value: '8', label: 'Тип 4 (NTC, 10 КОм, В=3950)'},
        {value: '9', label: 'Датчик протечки WSP'},
        {value: '10', label: 'Датчик протечки WSP + (36 КОм)'},
        {value: '11', label: 'Сухой контакт НО'},
        {value: '12', label: 'Сухой контакт НЗ'},
        {value: '13', label: '4-20мА (Rh=120 Ом ±0.1%)'},
        {value: '14', label: 'Датчик тока 1/1024 сек'}
    ]
}

export let timeIntoCloserDate = (hour, min, sec) => {
    if (hour > 0) {
        if (hour % 24 === 0) return hour / 24 + " сут"
        return hour + " час"
    }
    if (min > 0) {
        if (min % 1440 === 0) return min / 1440 + " сут"
        if (min % 60 === 0) return min / 60 + " час"
        return min + " мин"
    }
    if (sec > 0) {
        if (sec % 86400 === 0) return sec / 60 + " сут"
        if (sec % 3600 === 0) return sec / 60 + " час"
        if (sec % 60 === 0) return sec / 60 + " мин"
        return sec + " сек"
    } else return "Выключено"
}

export let sn6_8valid = (sn) => {
    return /^[0-9]+$/i.test(sn);
}

export let sn12valid = (sn) => {
    return /^[a-fA-F0-9]+$/i.test(sn);
}

import instance from './Instance'
import * as AppConstants from "../utils/constants/AppConstants";
import * as axios from "axios";

export const ProfileApi = {
    getProfile(sid) {
        return (
            axios
                .get(AppConstants.PROFILE_URL_PATH + `?sid=${sid}`)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    setProfile(params) {
        return (
            axios
                .post(AppConstants.PROFILE_URL_PATH, params)
                .then(response => {
                        return response.data
                    }
                )

        )
    },
    setNewPassword(params) {
        return (
            axios
                .post(AppConstants.PROFILE_URL_PATH, params)
                .then(response => {
                        return response.data
                    }
                )

        )
    }
}
